// convertToMp3.js
import * as lamejs from "@breezystack/lamejs";

export const encodeAudioBufferToMp3 = async (audioBuffer, bitrate = 64) => {
  const offlineContext = new OfflineAudioContext(
    2,
    audioBuffer.length,
    audioBuffer.sampleRate
  );

  const source = offlineContext.createBufferSource();
  source.buffer = audioBuffer;

  const gainNode = offlineContext.createGain();
  gainNode.gain.value = Math.pow(10, -3 / 20); // -3dB

  source.connect(gainNode);
  gainNode.connect(offlineContext.destination);

  source.start();
  const processedBuffer = await offlineContext.startRendering();

  return convertBufferToMp3(processedBuffer, bitrate);
};

const convertBufferToMp3 = async (audioBuffer, bitrate = 64) => {
  const mp3encoder = new lamejs.Mp3Encoder(2, audioBuffer.sampleRate, bitrate);
  const buffers = [
    audioBuffer.getChannelData(0),
    audioBuffer.getChannelData(1),
  ];
  let mp3Data = [];
  const sampleBlockSize = 1152;

  for (let i = 0; i < buffers[0].length; i += sampleBlockSize) {
    const leftChunk = convertFloat32ToInt16(
      buffers[0].subarray(i, i + sampleBlockSize)
    );
    const rightChunk = convertFloat32ToInt16(
      buffers[1].subarray(i, i + sampleBlockSize)
    );
    const mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);
    if (mp3buf.length > 0) mp3Data.push(new Int8Array(mp3buf));
  }

  const flush = mp3encoder.flush();
  if (flush.length > 0) mp3Data.push(new Int8Array(flush));

  return new Blob(mp3Data, { type: "audio/mp3" });
};

const convertFloat32ToInt16 = (buffer) => {
  const int16Buffer = new Int16Array(buffer.length);
  for (let i = 0; i < buffer.length; i++) {
    int16Buffer[i] = buffer[i] * 32767.5;
  }
  return int16Buffer;
};
