import React, { useRef, useState } from "react";
import * as lamejs from "@breezystack/lamejs";
import {
  getStorage,
  ref as firebaseRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import "./css/Sellbeat.css";
import client from "./appwriteConfig";
import { Client, Databases, Storage, ID } from "appwrite";
import { app } from "./firebaseConfig"; // Ensure you have this file with your Firebase config
import { useUser } from "./UserContext";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import genres from "./genreBeat";
import { encodeAudioBufferToMp3 } from "./convertToMp3";
function SellBeat() {
  const { user } = useUser();
  const [mp3File, setMp3File] = useState(null);
  const [beatName, setBeatName] = useState("");
  const [originalMp3Url, setOriginalMp3Url] = useState("");
  const [convertedMp3Url, setConvertedMp3Url] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [artistName, setArtistName] = useState("");
  const [genre, setGenre] = useState("");
  const [tempo, setTempo] = useState("");
  const [cost, setCost] = useState("");
  const storage = getStorage(app);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();

  const [selectedGenres, setSelectedGenres] = useState([]);

  const genreOptions = genres.map((genre) => ({ value: genre, label: genre }));
  const handleGenreChange = (selectedOptions) => {
    setSelectedGenres(selectedOptions.map((option) => option.value));
  };
  const handleAudioChange = (event) => {
    setMp3File(event.target.files[0]);
  };

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const uploadFile = async (fileBlob, filePath) => {
    const fileRef = firebaseRef(storage, filePath);
    await uploadBytes(fileRef, fileBlob);
    return getDownloadURL(fileRef);
  };
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const UploadSellBeatData = async () => {
    if (!mp3File || !imageFile) return;
    setLoading(true);
    setError(null);
    try {
      const capitalizedBeatName = capitalizeFirstLetterOfEachWord(beatName);
      const capitalizedArtistName = capitalizeFirstLetterOfEachWord(artistName);
      const audioFileType = mp3File.name.split(".").pop();
      const audioFileName = `${capitalizedArtistName}_${capitalizedBeatName}.${audioFileType}`;
      // Original MP3 upload and URL setting
      const originalAudioPath = `beats/${user.email}/${capitalizedBeatName}/${audioFileName}`;
      const originalUrl = await uploadFile(mp3File, originalAudioPath);
      setOriginalMp3Url(originalUrl);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        if (audioBuffer.numberOfChannels !== 2) {
          alert("The file must be a stereo audio file.");
          return;
        }

        const mp3Blob = await encodeAudioBufferToMp3(audioBuffer, 64); // Convert at 64 kbps
        const convertedAudioPath = `beats/${user.email}/${capitalizedBeatName}/low_${capitalizedArtistName}_${capitalizedBeatName}.mp3`;
        const convertedUrl = await uploadFile(mp3Blob, convertedAudioPath);
        setConvertedMp3Url(convertedUrl);

        const imageFileType = imageFile.name.split(".").pop();
        const imageFileName = `${capitalizedBeatName}.${imageFileType}`;
        const imagePath = `beats/${user.email}/${capitalizedBeatName}/albumArt/${capitalizedArtistName}_${imageFileName}`;
        const uploadedImageUrl = await uploadFile(imageFile, imagePath);
        setImageUrl(uploadedImageUrl);
        const genreString = selectedGenres.join(", ");
        const productData = {
          artistName: capitalizedArtistName,
          beatName: capitalizedBeatName,
          genre: genreString,
          tempo: parseInt(tempo, 10),
          cost: parseInt(cost, 10),
          audioUrl: originalUrl,
          lowAudioUrl: convertedUrl,
          email: user.email,
          imageUrl: uploadedImageUrl,
          audioPath: originalAudioPath,
          lowAudioPath: convertedAudioPath,
          imagePath,
        };

        const database = new Databases(client);
        await database.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID,
          ID.unique(),
          productData
        );
        setLoading(false);
        navigate("/beats");
      };
      reader.readAsArrayBuffer(mp3File);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#333",
      borderColor: state.isFocused ? "#666" : "#444",
      boxShadow: state.isFocused ? "0 0 0 1px #266" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#169" : "#555",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#222",
      color: "white",
      border: "1px solid #555",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#555"
        : state.isFocused
        ? "#333"
        : "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "#555",
        color: "white",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#555",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#999",
      "&:hover": {
        backgroundColor: "#777",
        color: "white",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };

  return (
    <div className="sell-beats-container">
      <h2>Sell Your Beat</h2>
      <div className="form-group">
        <label>Artist Name:</label>
        <input
          type="text"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Beat Name:</label>
        <input
          type="text"
          value={beatName}
          onChange={(e) => setBeatName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Genres:</label>
        <Select
          options={genreOptions}
          isMulti
          closeMenuOnSelect={false}
          onChange={handleGenreChange} // Updated function reference
          value={genreOptions.filter((option) =>
            selectedGenres.includes(option.value)
          )}
          placeholder="Select Genres"
          classNamePrefix="filter-dropdown" // Optional for custom styling
          styles={customStyles}
        />
      </div>
      <div className="form-group">
        <label>Tempo:</label>
        <input
          type="number"
          value={tempo}
          onChange={(e) => setTempo(parseInt(e.target.value, 10) || 0)}
          required
          min="0"
        />
      </div>
      <div className="form-group">
        <label>Cost:</label>
        <input
          type="number"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Upload Audio File:</label>
        <input
          type="file"
          accept="audio/mpeg, audio/wav"
          onChange={handleAudioChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Upload Image File:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          required
        />
      </div>

      <button className="onSubmitButton" onClick={UploadSellBeatData}>
        {" "}
        {loading ? (
          <>
            Uploading...
            <ClipLoader color="#ffffff" loading={loading} size={20} />{" "}
          </>
        ) : (
          "Submit"
        )}
      </button>
      {error && <div className="error-message">{error}</div>}

      {/* {originalMp3Url && (
        <div>
          <h2>Original MP3:</h2>
          <audio controls src={originalMp3Url}>
            Your browser does not support the audio tag.
          </audio>
          <a href={originalMp3Url} download="original_audio.mp3">
            Download Original MP3
          </a>
        </div>
      )}
      {convertedMp3Url && (
        <div>
          <h2>Converted MP3:</h2>
          <audio controls src={convertedMp3Url}>
            Your browser does not support the audio tag.
          </audio>
          <a href={convertedMp3Url} download="converted_audio.mp3">
            Download Converted MP3
          </a>
        </div>
      )}{" "}
      {imageUrl && (
        <div>
          <h2>Uploaded Image:</h2>
          <img src={imageUrl} alt="Uploaded" style={{ width: "300px" }} />
        </div>
      )} */}
    </div>
  );
}

export default SellBeat;
