import React, { useState, useEffect } from "react";
import { useUser } from "./UserContext";
import "./css/CartModal.css"; // Ensure this CSS correctly styles the modal
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FaTrash } from "react-icons/fa";
import { Databases } from "appwrite";
import client from "./appwriteConfig";

function CartModal({ isCartOpen, onCartClose }) {
  const { cart, removeFromCart, cartLoading, updateCartItem, storage } =
    useUser();
  const navigate = useNavigate();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [fashionImages, setFashionImages] = useState({});
  const [beatImages, setBeatImages] = useState({});
  const [tutorialImages, setTutorialImages] = useState({});
  const DbID = process.env.REACT_APP_DATABASE_ID;
  const BeatClxnID = process.env.REACT_APP_COLLECTION_BEATS_ID;
  const TutorialClxnID = process.env.REACT_APP_COLLECTION_TUTORIALS_ID;
  const database = new Databases(client);
  useEffect(() => {
    const initialQuantities = {};
    cart.forEach((item) => {
      initialQuantities[item.productId] = item.quantity;
    });
    setQuantities(initialQuantities);
  }, [cart]);

  useEffect(() => {
    // Fetch images for fashion, beat, and tutorial items in the cart
    const fetchImages = async () => {
      const fashionImg = {};
      const beatImg = {};
      const tutorialImg = {};

      for (const item of cart) {
        try {
          if (item.productType === "fashion" && item.FASHIONinfo) {
            const fashionInfo = JSON.parse(item.FASHIONinfo);
            const fileView = await storage.getFileView(
              process.env.REACT_APP_BUCKET_FASHION_ID,
              fashionInfo.fashionUrl
            );
            fashionImg[item.productId] = fileView.href;
          } else if (item.productType === "beat" && item.BEATinfo) {
            const beatInfo = JSON.parse(item.BEATinfo);
            const beatId = beatInfo.beatId;
            const beatDocument = await database.getDocument(
              DbID,
              BeatClxnID,
              beatId
            );
            beatImg[item.productId] = beatDocument.imageUrl;
          } else if (item.productType === "tutorial" && item.TUTORIALinfo) {
            const tutorialInfo = JSON.parse(item.TUTORIALinfo);
            const tutorialId = tutorialInfo.tutorialId;
            const tutorialDocument = await database.getDocument(
              DbID,
              TutorialClxnID,
              tutorialId
            );
            tutorialImg[item.productId] = tutorialDocument.thumbnailUrl; // Directly using the URL from TUTORIALinfo
          }
        } catch (error) {
          console.error("Failed to fetch image:", error);
        }
      }

      setFashionImages(fashionImg);
      setBeatImages(beatImg);
      setTutorialImages(tutorialImg);
    };

    fetchImages();
  }, [cart, storage]);

  const totalPrice = cart.reduce(
    (acc, curr) => acc + curr.cost * (quantities[curr.productId] || 1),
    0
  );

  if (!isCartOpen) return null;

  const handleQuantityChange = (itemId, change, stock) => {
    const currentQuantity = quantities[itemId] || 1;
    let newQuantity = currentQuantity + change;
    if (newQuantity < 1) newQuantity = 1;
    if (newQuantity > stock) newQuantity = stock;
    setQuantities((prev) => ({ ...prev, [itemId]: newQuantity }));
  };

  const handleProceedToPay = async () => {
    const updatedCart = [...cart];
    for (const productId in quantities) {
      const item = updatedCart.find((item) => item.productId === productId);
      if (item.quantity !== quantities[productId]) {
        item.quantity = quantities[productId];
        item.totalCost = item.cost * quantities[productId];
        await updateCartItem(productId, quantities[productId]);
      }
    }
    setIsRedirecting(true);
    onCartClose(); // Close the modal
    navigate("/checkout", { state: { cart: updatedCart, totalPrice } });
  };

  const handleRemoveFromCart = (productId) => {
    if (!cartLoading[productId]) {
      removeFromCart(productId);
    }
  };

  const groupedItems = groupItemsByProductType(cart);

  return (
    <div className="cart-modal-overlay" onClick={onCartClose}>
      <div className="cart-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="cart-modal-close" onClick={onCartClose}>
          &times;
        </span>
        <h2 className="cart-modal-title">Cart</h2>
        <div className="cart-modal-scrollable">
          {Object.keys(groupedItems).map((type) => (
            <div key={type}>
              <h3 className="cart-section-title">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </h3>
              <ul className="cart-modal-list">
                {groupedItems[type].map((item) => (
                  <li key={item.productId} className="cart-modal-item">
                    <div className="item-details">
                      {item.productType === "fashion" &&
                        fashionImages[item.productId] && (
                          <img
                            src={fashionImages[item.productId]}
                            alt={item.productName}
                            className="cart-item-image"
                          />
                        )}
                      {item.productType === "beat" &&
                        beatImages[item.productId] && (
                          <img
                            src={beatImages[item.productId]}
                            alt={item.productName}
                            className="cart-item-image"
                          />
                        )}
                      {item.productType === "tutorial" &&
                        tutorialImages[item.productId] && (
                          <img
                            src={tutorialImages[item.productId]}
                            alt={item.productName}
                            className="cart-item-image"
                          />
                        )}
                      <span className="cart-item-name">{item.productName}</span>
                      {item.productType === "fashion" && (
                        <div className="fashion-counter1">
                          <button
                            className="counter-button"
                            onClick={() =>
                              handleQuantityChange(
                                item.productId,
                                -1,
                                item.stock
                              )
                            }
                          >
                            -
                          </button>
                          <span className="counter-number">
                            {quantities[item.productId] || 1}
                          </span>
                          <button
                            className="counter-button"
                            onClick={() =>
                              handleQuantityChange(
                                item.productId,
                                1,
                                item.stock
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      )}
                    </div>
                    <span className="cart-item-cost">
                      {item.productType === "fashion"
                        ? `₹${item.cost} x ${quantities[item.productId]} = ₹${
                            item.cost * quantities[item.productId]
                          }`
                        : `₹${item.cost}`}
                    </span>
                    <button
                      className="cart-modal-remove"
                      onClick={() => handleRemoveFromCart(item.productId)}
                      disabled={cartLoading[item.productId]}
                    >
                      {cartLoading[item.productId] ? (
                        <ClipLoader size={10} color="#FFF" />
                      ) : (
                        <FaTrash />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="modalfooter">
          <div className="total-cart">Total: ₹ {totalPrice}</div>
          <div className="proceed-button">
            {cart.length > 0 && (
              <button onClick={handleProceedToPay}>Proceed to Pay</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartModal;

function groupItemsByProductType(cart) {
  return cart.reduce((groups, item) => {
    const type = item.productType;
    if (!groups[type]) {
      groups[type] = [];
    }
    groups[type].push(item);
    return groups;
  }, {});
}
