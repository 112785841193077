import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useUser } from "./UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import "./css/Checkout.css";
import { Databases } from "appwrite";
import client from "./appwriteConfig";

const statesOfIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];
const databases = new Databases(client);
const Checkout = () => {
  const location = useLocation();
  const { cart } = location.state || {};
  const { user } = useUser();
  const [loadingPay, setLoadingPay] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [appliedCoupons, setAppliedCoupons] = useState([]); // Array for max 3 coupons
  const [discountAmount, setDiscountAmount] = useState(0);
  const [cartState, setCartState] = useState([]);
  const [couponCode, setCouponCode] = useState("");

  const [availableCoupons, setAvailableCoupons] = useState([]);
  useEffect(() => {
    setCartState(
      (location.state?.cart || cart).map((item) => ({
        ...item,
        previousCost: item.cost,
        discountedCost: null,
      }))
    );
  }, [location.state?.cart, cart]);

  // Store all fetched coupons
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_COUPONS_ID
        );
        setAvailableCoupons(response.documents);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, []);
  const applyCoupon = () => {
    const enteredCode = couponCode.trim().toUpperCase();

    if (appliedCoupons.length >= 3) {
      alert("You can only apply up to 3 coupons.");
      return;
    }

    const validCoupon = availableCoupons.find(
      (coupon) => coupon.couponCode === enteredCode
    );

    if (!validCoupon) {
      alert("Invalid Coupon Code.");
      return;
    }

    if (appliedCoupons.some((coupon) => coupon.couponCode === enteredCode)) {
      alert("Coupon already applied.");
      return;
    }

    let discount = 0;
    let newCart = cartState.map((item) => {
      if (item.productType === validCoupon.category) {
        const itemDiscount = (validCoupon.discountPercent / 100) * item.cost;
        discount += itemDiscount;

        return {
          ...item,
          previousCost: item.previousCost || item.cost,
          cost: parseFloat((item.cost - itemDiscount).toFixed(2)),
          discountedCost: item.cost - itemDiscount,
        };
      }
      return item;
    });

    if (discount > 0) {
      setDiscountAmount(discountAmount + parseFloat(discount.toFixed(2)));
      setAppliedCoupons([...appliedCoupons, validCoupon]);
      setCouponCode("");
      setCartState(newCart);
    } else {
      alert("Coupon is not applicable to items in your cart.");
    }
  };
  const removeCoupon = (couponId) => {
    const couponToRemove = appliedCoupons.find(
      (coupon) => coupon.$id === couponId
    );
    if (!couponToRemove) return;

    let updatedCart = cartState.map((item) => {
      if (item.productType === couponToRemove.category) {
        const previouslyAppliedCoupons = appliedCoupons.filter(
          (coupon) =>
            coupon.$id !== couponId && coupon.category === item.productType
        );

        let updatedCost = item.previousCost ?? item.cost;

        previouslyAppliedCoupons.forEach((coupon) => {
          const itemDiscount = (coupon.discountPercent / 100) * updatedCost;
          updatedCost = parseFloat((updatedCost - itemDiscount).toFixed(2));
        });

        return {
          ...item,
          cost: updatedCost,
          discountedCost:
            updatedCost !== (item.previousCost ?? item.cost)
              ? updatedCost
              : null,
          previousCost: item.previousCost ?? item.cost,
        };
      }
      return item;
    });

    let removedDiscount = cartState.reduce(
      (sum, item) =>
        sum +
        (item.productType === couponToRemove.category &&
        item.discountedCost !== null
          ? (item.previousCost ?? item.cost) - item.discountedCost
          : 0),
      0
    );

    setDiscountAmount((prev) =>
      parseFloat((prev - removedDiscount).toFixed(2))
    );
    setAppliedCoupons(
      appliedCoupons.filter((coupon) => coupon.$id !== couponId)
    );
    setCartState(updatedCart);
  };

  const getPriceDetails = (cartState) => {
    let totalPrice = cartState.reduce(
      (sum, item) => sum + Number(item.cost || item.previousCost || 0), // ✅ Prevent null cost
      0
    );

    return { totalPrice };
  };

  // const { totalPrice } = getPriceDetails(location.state?.cart || cartState);

  const groupedItems = cartState.reduce((groups, item) => {
    const type = item.productType;
    if (!groups[type]) {
      groups[type] = [];
    }
    groups[type].push(item);
    return groups;
  }, {});

  const hasFashionItems =
    groupedItems.fashion && groupedItems.fashion.length > 0;
  const formatField = (field) => field.trim();

  const updatedCart = cartState.map((item) => ({
    productId: item.productId,
    productName: item.productName,
    productType: item.productType,
    sellerEmail: item.sellerEmail, // ✅ Preserve seller email
    quantity: item.quantity,
    cost: item.cost, // ✅ Updated cost after discount
    totalCost: parseFloat(
      (item.discountedCost ?? item.cost * item.quantity).toFixed(2)
    ), // ✅ Ensure total cost is correct
    previousCost: item.previousCost ?? item.cost,
    discountedCost: item.discountedCost,
    platformFee:
      item.platformFee ??
      parseFloat(
        (item.discountedCost ?? item.cost * item.quantity * 0.3).toFixed(2)
      ), // ✅ Apply platform fee (30%)
    sellerCost:
      item.sellerCost ??
      parseFloat(
        (item.discountedCost ?? item.cost * item.quantity * 0.7).toFixed(2)
      ), // ✅ Apply seller cost (70%)
    BEATinfo: item.BEATinfo,
    FASHIONinfo: item.FASHIONinfo,
    TUTORIALinfo: item.TUTORIALinfo,
  }));
  const getTotalPrice = () => {
    return cartState.reduce((sum, item) => {
      const itemTotal =
        item.productType === "fashion" ? item.cost * item.quantity : item.cost;
      return sum + itemTotal;
    }, 0);
  };
  const productInfo = updatedCart.map((item) => item.productName).join(", ");
  const finalPrice = getTotalPrice().toFixed(2);
  const handlePayment = async (values) => {
    setIsProcessing(true);
    setLoadingPay(true); // Show spinner
    try {
      const txnid = `TXN${Date.now()}`; // Unique transaction ID
      const productInfo = cart.map((item) => item.productName).join(", "); // Concatenate product names
      const formattedAddress = [
        values.address.flat ? formatField(values.address.flat) : "",
        values.address.street ? formatField(values.address.street) : "",
        values.address.area ? formatField(values.address.area) : "",
        values.address.city ? formatField(values.address.city) : "",
        values.address.state ? formatField(values.address.state) : "",
        values.address.postalCode ? formatField(values.address.postalCode) : "",
      ]
        .filter(Boolean)
        .join(", ")
        .replace(/,\s*$/, "")
        .trim();
      // Request hash generation from backend
      const hashResponse = await fetch(
        "https://api.hxabyte.com/generate-hash",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            key: "3MMJeP",
            txnid,
            amount: finalPrice,
            email: user.email,
            firstname: user.name.split(" ")[0] || user.name,
            productinfo: productInfo,
            phone: values.mobileNumber,
            CustomerAddress: formattedAddress,
            updatedCart,
          }),
        }
      );

      const hashData = await hashResponse.json();
      if (hashData.success) {
        // Create and submit the form dynamically
        const form = document.createElement("form");
        form.action = "https://secure.payu.in/_payment";
        form.method = "POST";
        form.target = "_self"; // Opens in the same tab

        // Add required fields to the form
        const fields = {
          key: "3MMJeP",
          txnid,
          amount: finalPrice,
          email: user.email,
          firstname: user.name.split(" ")[0] || user.name,
          lastname: user.name.split(" ")[1] || "",
          productinfo: productInfo,
          surl: `https://api.hxabyte.com/verify-status/${txnid}`,
          furl: `https://api.hxabyte.com/verify-status/${txnid}`,

          phone: values.mobileNumber,
          hash: hashData.hash, // Add hash from backend
        };

        for (const [key, value] of Object.entries(fields)) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;
          form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit(); // Submit the form to PayU
        form.remove();
      } else {
        alert("Failed to generate hash. Please try again.");
      }
    } catch (error) {
      console.error("Error during payment:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsProcessing(false);
      setLoadingPay(false); // Hide spinner
    }
  };
  // Remove Coupon

  return (
    <div className="checkout-container">
      <div className="form-container-checkout">
        <h2 className="title-checkout">Checkout</h2>
        <div>
          {Object.keys(groupedItems).map((type) => (
            <div key={type}>
              <h4 className="cart-section-title-checkout">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </h4>
              <ul className="cart-item-list-checkout">
                {groupedItems[type].map((item, index) => (
                  <li key={index} className="cart-item-checkout">
                    <span className="product-name-checkout">
                      {item.productName}{" "}
                      {item.productType === "fashion" && item.quantity > 1
                        ? ` x ${item.quantity}`
                        : ""}
                    </span>
                    <span className="product-cost-checkout">
                      {item.discountedCost !== null ? (
                        <>
                          <s className="previous-cost">
                            {item.previousCost * item.quantity}
                          </s>{" "}
                          <strong className="discounted-cost">
                            ₹ {(item.cost * item.quantity).toFixed(2)}
                          </strong>
                        </>
                      ) : (
                        <>
                          {item.productType === "fashion" ? (
                            <span>
                              ₹{item.cost} x {item.quantity} = ₹
                              {(item.cost * item.quantity).toFixed(2)}
                            </span>
                          ) : (
                            <span>₹ {item.cost.toFixed(2)}</span>
                          )}
                        </>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <p className="total-price-checkout">
          <strong>Total Price:</strong> ₹{finalPrice}
        </p>
        <div>
          <h3 className="payment-title-checkout">Buyer Details</h3>
          <Formik
            initialValues={{
              mobileNumber: "",
              address: {
                flat: "",
                street: "",
                area: "",
                city: "",
                state: "",
                postalCode: "",
              },
            }}
            validate={(values) => {
              const errors = {};
              if (!/^[0-9]+$/.test(values.mobileNumber)) {
                errors.mobileNumber = "Only numbers are allowed";
              }
              if (hasFashionItems) {
                [
                  "flat",
                  "street",
                  "area",
                  "city",
                  "state",
                  "postalCode",
                ].forEach((field) => {
                  if (!values.address[field]) {
                    errors.address = {
                      ...errors.address,
                      [field]: "Required",
                    };
                  }
                });
                if (
                  values.address.postalCode &&
                  !/^[0-9]+$/.test(values.address.postalCode)
                ) {
                  errors.address = {
                    ...errors.address,
                    postalCode: "Only numbers are allowed",
                  };
                }
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              handlePayment(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <label className="form-label-checkout">Mobile Number</label>
                <Field
                  className="input-field-checkout"
                  type="text"
                  name="mobileNumber"
                  required
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="error-message-checkout"
                />

                {hasFashionItems && (
                  <>
                    <h3 className="address-title-checkout">Address Details</h3>
                    {["flat", "street", "area", "city", "postalCode"].map(
                      (field) => (
                        <div key={field}>
                          <label className="form-label-checkout">
                            {field.charAt(0).toUpperCase() + field.slice(1)}
                          </label>
                          <Field
                            className="input-field-checkout"
                            type="text"
                            name={`address.${field}`}
                            required
                          />
                          <ErrorMessage
                            name={`address.${field}`}
                            component="div"
                            className="error-message-checkout"
                          />
                        </div>
                      )
                    )}

                    <label className="form-label-checkout">State</label>
                    <Field
                      as="select"
                      className="select-field-checkout"
                      name="address.state"
                      required
                    >
                      <option value="" label="Select state" />
                      {statesOfIndia.map((state) => (
                        <option key={state} value={state} label={state} />
                      ))}
                    </Field>
                    <ErrorMessage
                      name="address.state"
                      component="div"
                      className="error-message-checkout"
                    />
                  </>
                )}

                {/* Coupon Section */}
                <div className="coupon-section">
                  <div className="coupon-typer">
                    <input
                      type="text"
                      placeholder="Enter Coupon Code"
                      value={couponCode}
                      onChange={(e) =>
                        setCouponCode(e.target.value.toUpperCase())
                      }
                      className="input-field-checkout"
                    />
                    <button
                      type="button"
                      onClick={applyCoupon}
                      className="apply-coupon-button"
                    >
                      Apply Coupon
                    </button>
                  </div>
                  <div className="applied-coupons">
                    {appliedCoupons.map((coupon) => (
                      <div key={coupon.$id} className="applied-coupon-item">
                        <span>
                          {coupon.couponCode} - {coupon.discountPercent}%
                        </span>
                        <button onClick={() => removeCoupon(coupon.$id)}>
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Price Details */}
                <div className="price-details">
                  {discountAmount > 0 && (
                    <p className="discount-applied">
                      <strong>Total Discount:</strong> - ₹{discountAmount}
                    </p>
                  )}

                  <p className="final-price-checkout">
                    <strong>Final Price : </strong> ₹{finalPrice}
                  </p>
                </div>

                {/* Payment Button */}
                <button
                  className="submit-button-checkout"
                  type="submit"
                  disabled={loadingPay || isSubmitting}
                >
                  {loadingPay ? (
                    <ClipLoader
                      color="#ffffff"
                      loading={loadingPay}
                      size={20}
                    />
                  ) : (
                    "Proceed to Pay NOW"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
