import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Databases, Query } from "appwrite";
import client from "./appwriteConfig";
import { useUser } from "./UserContext";
import { BeatLoader, ClipLoader } from "react-spinners";
import { FaPlay, FaShoppingCart } from "react-icons/fa";
import Modal from "react-modal";
import { BsInfoCircle } from "react-icons/bs";
import Carousal from "./Carousal";
import ShareButton from "./ShareButton";

function SeparateLearnMusic() {
  const { tutorialTitle } = useParams();
  const { user, cart, addToCart, cartLoading, fetchCartData } = useUser();
  const [tutorial, setTutorial] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, [user]);
  useEffect(() => {
    const fetchTutorial = async () => {
      setLoading(true);
      const database = new Databases(client);
      const sanitizedTitle = tutorialTitle.replace(/-/g, " ");
      const response = await database.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
        [Query.equal("tutorialTitle", sanitizedTitle)]
      );

      if (response.documents.length > 0) {
        const detailedTutorial = response.documents[0];
        setTutorial({
          ...detailedTutorial,
          videosData: JSON.parse(detailedTutorial.videos),
          thumbnailUrls: JSON.parse(detailedTutorial.videos).map((video) => ({
            videoUrl: video.videoUrl,
            thumbnailUrl: video.thumbnailUrl,
            videoName: video.videoName.replace(".mp4", ""), // Assuming the video name ends with .mp4
          })),
          inCart: cart.some((item) => item.productId === detailedTutorial.$id),
        });
      }
      setLoading(false);
    };

    fetchTutorial();
  }, [tutorialTitle, cart]);

  if (!tutorial || loading)
    return (
      <div>
        {" "}
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <BeatLoader color="#3498db" />
        </div>
      </div>
    );
  const isInCart = (productId) =>
    cart.some((item) => item.productId === productId);
  const handleAddToCart = () => {
    if (!isInCart(tutorial.$id)) {
      addToCart({
        ...tutorial,
        TUTORIALinfo: JSON.stringify({
          tutorialTitle: tutorial.tutorialTitle,
          videoUrl: tutorial.videosData[0].videoUrl,
          authorName: tutorial.authorName,
          thumbnailUrl: tutorial.thumbnailUrls[0].thumbnailUrl,
          cost: tutorial.cost,
          email: user.email,
        }),
        productType: "tutorial",
      });
    }
  };
  const handlePlay = async (videoUrl, isFree, tutorial) => {
    if (isFree) {
      setPlayingVideo({ id: videoUrl, url: videoUrl });
    } else {
      setSelectedTutorial(tutorial);
      setModalIsOpen(true);
    }
  };
  const toggleInfo = () => setInfoVisible(!infoVisible); // Toggle the info visibility
  const extractFileName = (fileName) => {
    if (!fileName) {
      console.error("FileName is undefined");
      return ""; // Return an empty string or some default value if filename is undefined
    }
    const parts = fileName.split("_");
    return parts.slice(2).join("_");
  };
  return (
    <div className="learn-music-container">
      <div className="seperate-title">
        <h1>{tutorial.tutorialTitle}</h1>
        <ShareButton />
      </div>

      <div className="tutorial-card">
        <p>{tutorial.description}</p>
        <div className="video-card">
          <div className="video-carded">
            {tutorial.thumbnailUrls.map((thumbnail, idx) => {
              // Remove .mp4 from videoName and add numbering
              const formattedVideoName = `${idx + 1}. ${extractFileName(
                thumbnail.videoName
              )}`;
              const isFirstVideo = idx === 0; // Determine if it's the first video

              return (
                <div key={idx} className="thumbnail-container">
                  {playingVideo && playingVideo.id === thumbnail.videoUrl ? (
                    <div className="video-whileplay">
                      <video
                        controls
                        autoPlay
                        className="video-playing"
                        controlsList="nodownload"
                      >
                        <source src={playingVideo.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <p className="video-name">{formattedVideoName}</p>
                    </div>
                  ) : (
                    <>
                      <img
                        src={thumbnail.thumbnailUrl}
                        alt="Thumbnail"
                        className={`thumbnail-lrnmus ${
                          !isFirstVideo ? "not-first-video" : ""
                        }`}
                      />
                      <button
                        className="lrnmus-play-button"
                        onClick={() =>
                          handlePlay(thumbnail.videoUrl, isFirstVideo, tutorial)
                        }
                      >
                        <FaPlay />
                      </button>
                      <p className="video-name">{formattedVideoName}</p>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div className="video-details">
            <div className="details-row">
              <div className="detail-tut">
                <p>Author:</p> {tutorial.authorName}
              </div>
              <div className="detail-tut">
                <p>Category:</p> {tutorial.category}
              </div>
              <div className="detail-tut">
                <p> Duration:</p> {tutorial.duration}
              </div>
              <div className="detail-tut">
                <p>No. of videos:</p> {tutorial.videosData.length}
              </div>
            </div>
            <div className="cart-button-tut">
              <button
                className="add-to-cart-button in-cart"
                onClick={() => handleAddToCart(tutorial)}
                disabled={isInCart(tutorial.$id)}
              >
                <p className="video-cost">₹ {tutorial.cost}</p>
                {cartLoading[tutorial.$id] ? (
                  <ClipLoader size={10} color="#FFF" /> // Spinner shows while loading
                ) : isInCart(tutorial.$id) ? (
                  "In Cart"
                ) : (
                  "ADD"
                )}
                <FaShoppingCart />
              </button>
              <button onClick={toggleInfo} className="info-btn-lm">
                <BsInfoCircle /> {/* Display the information icon */}
              </button>
              {infoVisible && (
                <div className="info-message-lm">
                  The first video is free as a preview—unlock full access to all
                  course videos by purchasing now!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Buy Now Modal"
        className="buy-now-modal"
        overlayClassName="buy-now-overlay"
      >
        <div className="buy-now-header">
          <h2>Buy Now to Access Full Course</h2>
          <span
            className="buy-modal-close"
            onClick={() => setModalIsOpen(false)}
          >
            &times;
          </span>
        </div>
        <div>
          <h3>{tutorial.tutorialTitle}</h3>
          <p>Author: {tutorial.authorName}</p>
          <p>Category: {tutorial.category}</p>
          <p>Duration: {tutorial.duration}</p>
          <p>Cost: ₹{tutorial.cost}</p>
          <div className="cart-button-tut">
            <button
              className="add-to-cart-button in-cart"
              onClick={() => handleAddToCart(tutorial)}
              disabled={isInCart(tutorial.$id)}
            >
              <p className="video-cost">₹ {tutorial.cost}</p>
              {cartLoading[tutorial.$id] ? (
                <ClipLoader size={10} color="#FFF" /> // Spinner shows while loading
              ) : isInCart(tutorial.$id) ? (
                "In Cart"
              ) : (
                "ADD"
              )}
              <FaShoppingCart />
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SeparateLearnMusic;
