import {
  isPieceMovable,
  generateLegalMoves,
  filterOutMovesThatCauseCheck,
  getCastlingMoves,
  isKingInCheck,
  isCheckmate,
} from "./chessFunctions";
import pieceMoveSound from "./sounds/piece_move.wav";
import pieceTakesSound from "./sounds/piece_takes.wav";
import castlingSound from "./sounds/castling.wav";
import checkSound from "./sounds/check.wav";
import checkmateSound from "./sounds/checkmate.wav";
import queenMoveSound from "./sounds/queen_move.wav";

// Helper function to play prioritized sounds
const playPrioritizedSound = (soundType, volume) => {
  let soundToPlay = null;

  switch (soundType) {
    case "checkmate":
      soundToPlay = checkmateSound;
      break;
    case "check":
      soundToPlay = checkSound;
      break;
    case "castling":
      soundToPlay = castlingSound;
      break;
    case "queenMove":
      soundToPlay = queenMoveSound;
      break;
    case "pieceMove":
      soundToPlay = pieceMoveSound;
      break;
    case "capture":
      soundToPlay = pieceTakesSound; // Sound for capturing a piece
      break;
    default:
      return;
  }

  if (soundToPlay) {
    const audio = new Audio(soundToPlay);
    audio.volume = volume;
    audio.play();
  }
};

// Helper function to generate chess notation
const getChessNotation = (row, col) => {
  const files = ["a", "b", "c", "d", "e", "f", "g", "h"];
  return `${files[col]}${8 - row}`;
};

// Main handleSquareClick function
export const handleSquareClick = ({
  row,
  col,
  board,
  selectedPiece,
  setSelectedPiece,
  legalMoves,
  setLegalMoves,
  setBoard,
  isWhiteTurn,
  setIsWhiteTurn,
  inCheck,
  setInCheck,
  checkmate,
  setCheckmate,
  setShowGameOverModal,
  setWinner,
  castlingRights,
  setCastlingRights,
  setPromotion,
  promotion,
  moves,
  setMoves,
  gameId,
  selectedColor,
  gameStarted,
  volume,
  saveMoveToAppwrite, // Assuming this is passed in for saving moves
}) => {
  if (
    checkmate ||
    !gameStarted || // Ensure that the game has started
    (selectedColor === "black" && isWhiteTurn) ||
    (selectedColor === "white" && !isWhiteTurn)
  )
    return;

  const piece =
    board[selectedPiece ? selectedPiece.row : row][
      selectedPiece ? selectedPiece.col : col
    ];
  let soundToPlay = "pieceMove"; // Default to normal piece move sound

  if (selectedPiece) {
    if (legalMoves.some((move) => move[0] === row && move[1] === col)) {
      const newBoard = board.map((r) => [...r]);
      const movingPiece = board[selectedPiece.row][selectedPiece.col];
      const targetPiece = board[row][col]; // The piece on the target square
      const isWhite = movingPiece === movingPiece.toUpperCase();

      newBoard[row][col] = movingPiece;
      newBoard[selectedPiece.row][selectedPiece.col] = "";
      const moveNotation = getChessNotation(
        selectedPiece.row,
        selectedPiece.col,
        row,
        col,
        movingPiece
      );

      // Log the move to the console
      console.log(`${isWhite ? "White" : "Black"} move: ${moveNotation}`);

      if (
        movingPiece.toLowerCase() === "k" &&
        Math.abs(col - selectedPiece.col) === 2
      ) {
        soundToPlay = "castling"; // Higher priority than normal move

        if (col === 6) {
          newBoard[row][5] = newBoard[row][7];
          newBoard[row][7] = "";
          moveNotation = "O-O"; // Kingside castling
        } else if (col === 2) {
          newBoard[row][3] = newBoard[row][0];
          newBoard[row][0] = "";
          moveNotation = "O-O-O"; // Queenside castling
        }
      }

      // Promotion logic
      const isWhitePawnPromotion = movingPiece === "P" && row === 0;
      const isBlackPawnPromotion = movingPiece === "p" && row === 7;

      if (isWhitePawnPromotion || isBlackPawnPromotion) {
        setBoard(newBoard); // Update the board immediately
        setPromotion({
          row,
          col,
          color: isWhiteTurn ? "white" : "black",
        });
        return; // Wait for promotion before continuing
      }

      if (!moveNotation) {
        // Generate move notation
        const fromNotation = getChessNotation(
          selectedPiece.row,
          selectedPiece.col
        );
        const toNotation = getChessNotation(row, col);
        moveNotation = targetPiece
          ? `${fromNotation[0]}x${toNotation}` // Capture notation for pawns
          : `${toNotation}`; // Normal move
      }

      let fullMoveNotation = "";
      let movesString = "";

      if (isWhite) {
        const moveNumber = `${Math.floor((moves.length + 2) / 2)}. `;
        fullMoveNotation = `${moveNumber}${moveNotation}`;
      } else {
        fullMoveNotation = `${moveNotation}`;
      }

      movesString = moves.join(" ");
      const updatedMoves = isWhite
        ? `${movesString} ${fullMoveNotation}`
        : movesString.slice(0, -1) + ` ${fullMoveNotation}`;

      const updatedBoardState = JSON.stringify(newBoard);

      setBoard(newBoard);
      const moveData = {
        move: moveNotation,
        color: isWhite ? "white" : "black",
      };
      saveMoveToAppwrite(gameId, moveData);

      const opponentColor = isWhiteTurn ? "black" : "white";
      const isCheck = isKingInCheck(newBoard, opponentColor);
      const isMate = isCheckmate(newBoard, opponentColor);

      // Play capture sound if a piece is captured
      if (targetPiece) {
        soundToPlay = "capture"; // Play capture sound for piece takes
      }

      if (isMate) {
        soundToPlay = "checkmate";
        setCheckmate(true);
        setWinner(isWhiteTurn ? "White" : "Black");
        setShowGameOverModal(true);
      } else if (isCheck) {
        soundToPlay = "check";
        setInCheck({ ...inCheck, [opponentColor]: true });
      } else {
        setInCheck({ white: false, black: false });
      }

      // Ensure queenMove sound plays only for normal moves without capture, check, or checkmate
      if (
        movingPiece.toLowerCase() === "q" &&
        !targetPiece &&
        !isCheck &&
        !isMate
      ) {
        soundToPlay = "queenMove";
      }

      playPrioritizedSound(soundToPlay, volume);

      setSelectedPiece(null);
      setLegalMoves([]);
      setIsWhiteTurn(!isWhiteTurn); // Switch turns
    } else {
      setSelectedPiece(null);
      setLegalMoves([]);
    }
  } else {
    if (piece && isPieceMovable(piece, isWhiteTurn)) {
      setSelectedPiece({ row, col });
      const moves = generateLegalMoves(row, col, piece, board);
      const validMoves = filterOutMovesThatCauseCheck(
        row,
        col,
        moves,
        board,
        piece
      );

      if (piece.toLowerCase() === "k") {
        const castlingMoves = getCastlingMoves(
          row,
          col,
          isWhiteTurn,
          board,
          castlingRights
        );
        validMoves.push(...castlingMoves);
      }

      setLegalMoves(validMoves);
    }
  }
};
