import React, { useEffect, useState } from "react";
import { Databases, Query, Storage } from "appwrite";
import { useUser } from "./UserContext";
import client from "./appwriteConfig";
import { BeatLoader } from "react-spinners";
import "./css/Purchased.css";

const Purchased = () => {
  const { user } = useUser();
  const [purchasedItems, setPurchasedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedVideos, setLoadedVideos] = useState({});
  const [loadedAudio, setLoadedAudio] = useState({});

  useEffect(() => {
    const fetchPurchasedItems = async () => {
      if (!user) return;

      const databases = new Databases(client);
      const storage = new Storage(client);

      try {
        const response = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_TRANSACTIONS,
          [
            Query.equal("CustomerEmail", user.email),
            Query.equal("paymentStatus", "success"),
            Query.orderDesc("$createdAt"),
            Query.limit(30000),
          ]
        );

        const items = await Promise.all(
          response.documents.map(async (item) => {
            let cartItems = [];
            try {
              cartItems = JSON.parse(item.cart);
            } catch (error) {
              console.error("Failed to parse cart items:", error);
            }

            const updatedCartItems = await Promise.all(
              cartItems.map(async (product) => {
                // Skips products with missing critical data
                if (product.productType === "beat" && product.productId) {
                  try {
                    const beatData = await databases.getDocument(
                      process.env.REACT_APP_DATABASE_ID,
                      process.env.REACT_APP_COLLECTION_BEATS_ID,
                      product.productId
                    );
                    const imageUrl = beatData.imageUrl;
                    const audioUrl = beatData.audioUrl;
                    return { ...product, imageUrl, audioUrl };
                  } catch (error) {}
                  return null; // Skip this product
                }
                try {
                  // Handle Tutorials
                  if (product.productType === "tutorial" && product.productId) {
                    const tutorialData = await databases.getDocument(
                      process.env.REACT_APP_DATABASE_ID,
                      process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
                      product.productId
                    );

                    if (tutorialData.videos) {
                      const videos = JSON.parse(tutorialData.videos);
                      if (Array.isArray(videos)) {
                        product.videos = await Promise.all(
                          videos.map(async (video) => {
                            const videoUrl = video.videoUrl;
                            // console.log(videoUrl);
                            const thumbnailUrl = video.thumbnailUrl;
                            // console.log(thumbnailUrl);
                            return {
                              ...video,
                              videoUrl,
                              thumbnailUrl,
                            };
                          })
                        );
                      } else {
                        product.videos = [];
                      }
                    } else {
                      product.videos = [];
                    }
                  }
                } catch (error) {}

                try {
                  // Handle Fashion
                  if (product.fashionUrls) {
                    product.fashionUrlLink = await getFileViewUrl(
                      storage,
                      process.env.REACT_APP_BUCKET_FASHION_ID,
                      product.fashionUrls
                    );
                  }
                } catch (error) {
                  console.error("Error loading fashion data:", error);
                }

                return product;
              })
            );

            return {
              ...item,
              cart: updatedCartItems.filter((product) => product !== null),
            };
          })
        );

        setPurchasedItems(items);
      } catch (error) {
        console.error("Failed to fetch purchased items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedItems();
  }, [user]);
  const extractFileName = (fileName) => {
    if (!fileName) {
      console.error("FileName is undefined");
      return ""; // Return an empty string or some default value if filename is undefined
    }
    const parts = fileName.split("_");
    return parts.slice(2).join("_");
  };
  const getFileViewUrl = async (storage, bucketId, fileId) => {
    if (!fileId) {
      console.error("Missing fileId");
      return null;
    }

    try {
      const response = await storage.getFileView(bucketId, fileId);
      return response.href;
    } catch (error) {
      console.error("Failed to get file view URL:", error);
      return null;
    }
  };

  const loadAudio = async (audioUrl) => {
    if (loadedAudio[audioUrl]) return; // Prevent multiple fetches

    try {
      setLoadedAudio((prev) => ({
        ...prev,
        [audioUrl]: audioUrl, // Store the URL
      }));
    } catch (error) {
      console.error(`Failed to load auddio for ID ${audioUrl}:`, error);
    }
  };

  const loadVideo = async (videoUrl) => {
    if (!videoUrl || loadedVideos[videoUrl]) return;

    // Assuming 'videoUrl' is directly usable and correctly formatted
    setLoadedVideos((prev) => ({
      ...prev,
      [videoUrl]: videoUrl, // Directly store the URL
    }));
  };

  return (
    <div className="purchased-container">
      <h2>Purchased Items</h2>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <BeatLoader color="#3498db" />
        </div>
      ) : (
        <>
          {purchasedItems.length === 0 ? (
            <div>No purchased items found.</div>
          ) : (
            <div className="purchased-list">
              {purchasedItems.map((item) => (
                <div className="purchased-item" key={item.$id}>
                  <div className="purchased-details">
                    <p>
                      <strong>Txn ID:</strong>
                      {item.merchTransID}
                    </p>
                    <p>
                      <strong>Payment ID:</strong>
                      {item.payUTransID}
                    </p>
                    <p>
                      <strong>Total Price:</strong> {item.totalPrice}
                    </p>
                    <p>
                      <strong>Purchase Date:</strong>
                      {new Date(item.$createdAt).toLocaleString()}
                    </p>
                  </div>

                  <div className="cart-items">
                    {item.cart.map((product, index) => (
                      <div className="cart-item" key={index}>
                        <div className="pur-cartitem">
                          <p>Cost: {product.cost}</p>
                          <p>Type: {product.productType}</p>
                          {product.productType === "fashion" && (
                            <p>Quantity: {product.quantity}</p>
                          )}
                        </div>

                        <h4 className="pur-productName">
                          {product.productName}
                        </h4>

                        {/* Beats */}
                        {product.imageUrl && (
                          <div className="purchased-audio-section">
                            <div className="thumbnail-wrapper">
                              <img
                                src={product.imageUrl}
                                alt="Album Art"
                                className="purchased-album-art-image"
                              />
                              <button
                                className="pur-play-button"
                                onClick={() => loadAudio(product.audioUrl)}
                              >
                                ▶
                              </button>
                            </div>

                            {loadedAudio[product.audioUrl] && (
                              <div className="neon-audio-container">
                                <audio
                                  controls
                                  className="purchased-audio-player"
                                >
                                  <source
                                    src={loadedAudio[product.audioUrl]}
                                    type="audio/mp3"
                                  />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Tutorials */}
                        {product.productType === "tutorial" &&
                          product.videos && (
                            <div className="tutorial-videos-container">
                              {product.videos.map((video, idx) => (
                                <div
                                  key={idx}
                                  className="tutorial-thumbnail-container"
                                >
                                  <div className="thumbnail-wrapper">
                                    {loadedVideos[video.videoUrl] ? (
                                      <video
                                        controls
                                        className="purchased-video-player"
                                      >
                                        <source
                                          src={loadedVideos[video.videoUrl]}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        element.
                                      </video>
                                    ) : (
                                      <>
                                        <img
                                          src={video.thumbnailUrl}
                                          alt={video.videoName}
                                          className="thumbnail-image"
                                        />
                                        <button
                                          className="pur-play-button"
                                          onClick={() =>
                                            loadVideo(video.videoUrl)
                                          }
                                        >
                                          ▶
                                        </button>
                                      </>
                                    )}
                                  </div>
                                  <h5>
                                    {idx + 1}.
                                    {extractFileName(video.videoName).replace(
                                      /\.mp4$/,
                                      ""
                                    )}
                                  </h5>
                                </div>
                              ))}
                            </div>
                          )}

                        {/* Fashion */}
                        {product.fashionUrlLink && (
                          <img
                            src={product.fashionUrlLink}
                            alt="Fashion Item"
                            className="purchased-product-image"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Purchased;
