import { makeMove, getAllPossibleMoves } from "./chessFunctions";

// Positional values for pieces
const pieceSquareTables = {
  p: [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [5, 5, 5, 5, 5, 5, 5, 5],
    [1, 1, 2, 3, 3, 2, 1, 1],
    [0.5, 0.5, 1, 2.5, 2.5, 1, 0.5, 0.5],
    [0, 0, 0, 2, 2, 0, 0, 0],
    [0.5, -0.5, -1, 0, 0, -1, -0.5, 0.5],
    [0.5, 1, 1, -2, -2, 1, 1, 0.5],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  n: [
    [-5, -4, -3, -3, -3, -3, -4, -5],
    [-4, -2, 0, 0, 0, 0, -2, -4],
    [-3, 0, 1, 1.5, 1.5, 1, 0, -3],
    [-3, 0.5, 1.5, 2, 2, 1.5, 0.5, -3],
    [-3, 0, 1.5, 2, 2, 1.5, 0, -3],
    [-3, 0.5, 1, 1.5, 1.5, 1, 0.5, -3],
    [-4, -2, 0, 0.5, 0.5, 0, -2, -4],
    [-5, -4, -3, -3, -3, -3, -4, -5],
  ],
  b: [
    [-2, -1, -1, -1, -1, -1, -1, -2],
    [-1, 0, 0, 0, 0, 0, 0, -1],
    [-1, 0, 0.5, 1, 1, 0.5, 0, -1],
    [-1, 0.5, 0.5, 1, 1, 0.5, 0.5, -1],
    [-1, 0, 1, 1, 1, 1, 0, -1],
    [-1, 1, 1, 1, 1, 1, 1, -1],
    [-1, 0.5, 0, 0, 0, 0, 0.5, -1],
    [-2, -1, -1, -1, -1, -1, -1, -2],
  ],
  r: [
    [0, 0, 0, 0.5, 0.5, 0, 0, 0],
    [-0.5, 0, 0, 0, 0, 0, 0, -0.5],
    [-0.5, 0, 0, 0, 0, 0, 0, -0.5],
    [-0.5, 0, 0, 0, 0, 0, 0, -0.5],
    [-0.5, 0, 0, 0, 0, 0, 0, -0.5],
    [-0.5, 0, 0, 0, 0, 0, 0, -0.5],
    [0.5, 1, 1, 1, 1, 1, 1, 0.5],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  q: [
    [-2, -1, -1, -0.5, -0.5, -1, -1, -2],
    [-1, 0, 0, 0, 0, 0, 0, -1],
    [-1, 0, 0.5, 0.5, 0.5, 0.5, 0, -1],
    [-0.5, 0, 0.5, 0.5, 0.5, 0.5, 0, -0.5],
    [0, 0, 0.5, 0.5, 0.5, 0.5, 0, -0.5],
    [-1, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, -1],
    [-1, 0, 0.5, 0, 0, 0, 0, -1],
    [-2, -1, -1, -0.5, -0.5, -1, -1, -2],
  ],
  k: [
    [-3, -4, -4, -5, -5, -4, -4, -3],
    [-3, -4, -4, -5, -5, -4, -4, -3],
    [-3, -4, -4, -5, -5, -4, -4, -3],
    [-3, -4, -4, -5, -5, -4, -4, -3],
    [-2, -3, -3, -4, -4, -3, -3, -2],
    [-1, -2, -2, -2, -2, -2, -2, -1],
    [2, 2, 0, 0, 0, 0, 2, 2],
    [2, 3, 1, 0, 0, 1, 3, 2],
  ],
};

const pieceValues = {
  p: 10,
  n: 30,
  b: 30,
  r: 50,
  q: 90,
  k: 900,
};

// Function to evaluate the board
export const evaluateBoard = (board) => {
  let totalEvaluation = 0;

  for (let row = 0; row < 8; row++) {
    for (let col = 0; col < 8; col++) {
      const piece = board[row][col];
      if (piece) {
        // Ensure that the square is not empty
        const isWhite = piece === piece.toUpperCase();
        const pieceType = piece.toLowerCase();
        const pieceValue = pieceValues[pieceType];

        // Ensure row and col are within bounds (0-7) before accessing pieceSquareTables
        const positionValue =
          pieceSquareTables[pieceType] &&
          pieceSquareTables[pieceType][isWhite ? row : 7 - row] &&
          pieceSquareTables[pieceType][isWhite ? row : 7 - row][col];

        // Add position value only if it's defined and valid
        if (positionValue !== undefined) {
          totalEvaluation += (isWhite ? 1 : -1) * (pieceValue + positionValue);
        } else {
          totalEvaluation += (isWhite ? 1 : -1) * pieceValue; // Fallback to piece value if position value is not available
        }
      }
    }
  }

  return totalEvaluation;
};

// Minimax algorithm with alpha-beta pruning for better performance
export const minimax = (board, depth, isMaximizingPlayer, alpha, beta) => {
  if (depth === 0 || isGameOver(board)) {
    return evaluateBoard(board);
  }

  const possibleMoves = getAllPossibleMoves(
    isMaximizingPlayer ? "white" : "black",
    board
  );

  if (isMaximizingPlayer) {
    let maxEvaluation = -Infinity;
    for (const move of possibleMoves) {
      const newBoard = makeMove(board, move);
      let moveEvaluation = minimax(newBoard, depth - 1, false, alpha, beta);
      if (newBoard[move.endRow] && newBoard[move.endRow][move.endCol] !== "") {
        const capturedPiece = newBoard[move.endRow][move.endCol].toLowerCase();
        moveEvaluation += pieceValues[capturedPiece] || 0;
      }

      maxEvaluation = Math.max(maxEvaluation, moveEvaluation);
      alpha = Math.max(alpha, moveEvaluation);
      if (beta <= alpha) break; // Alpha-Beta Pruning
    }
    return maxEvaluation;
  } else {
    let minEvaluation = Infinity;
    for (const move of possibleMoves) {
      const newBoard = makeMove(board, move);
      let moveEvaluation = minimax(newBoard, depth - 1, true, alpha, beta);
      if (newBoard[move.endRow] && newBoard[move.endRow][move.endCol] !== "") {
        const capturedPiece = newBoard[move.endRow][move.endCol].toLowerCase();
        moveEvaluation -= pieceValues[capturedPiece] || 0;
      }

      minEvaluation = Math.min(minEvaluation, moveEvaluation);
      beta = Math.min(beta, moveEvaluation);
      if (beta <= alpha) break; // Alpha-Beta Pruning
    }
    return minEvaluation;
  }
};

// Helper function to check if the game is over
export const isGameOver = (board) => {
  // Implement logic to check for checkmate, stalemate, or draw
  return false; // Placeholder for now
};
