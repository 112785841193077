import { useEffect } from "react";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging } from "./firebaseConfig";
function Notify() {
  async function requestPermissionAndToken() {
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BHdKVlNNvnprBZ7IP3DfGDsXUZbp7aRUuePiK0RehbBUxif7HzD6oyYObN3SxcR4X82g4HblGGamPQf28xeroJU",
      });
      console.log(token);
    } else if (permission === "denied") {
      alert("you denied for notificatn");
    }
    // const token = await getToken(messaging, {
    //   vapidKey:
    //     "BB67Z5zejhCYMbP8z1S2hge7YhrIotsl8JB7PaHJO71wU5MQAZ1b5_PQEmyYvmS9yuOowh-HXuDaGAsrUaUApAw",
    // });
    // console.log(token);
  }
  useEffect(() => {
    requestPermissionAndToken();
  }, []); // Empty dependency array to run only on mount

  return null; // This component doesn't need to render anything
}

export default Notify;
