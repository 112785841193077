import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  FaTimes,
  FaPlay,
  FaPause,
  FaSave,
  FaTrash,
  FaMinus,
  FaPlus,
  FaStop,
  FaStopCircle,
  FaMicrophone,
} from "react-icons/fa";
import { useUser } from "./UserContext";
import "./css/MelodyModal.css";
import { Databases, Query } from "appwrite";
import client from "./appwriteConfig";
import InstrumentAudioContext from "./InstrumentAudioContext";
import { usePatternInsSeq } from "./PatternInsSeqContext";
import { debounce } from "lodash";
import { ClipLoader } from "react-spinners";

const databases = new Databases(client);

const generateNotes = () => {
  const notes = [];
  const noteNames = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  for (let octave = 0; octave < 6; octave++) {
    for (const note of noteNames) {
      notes.push(`${note}${octave}`);
    }
  }
  return notes;
};

const keyToNoteMap = {
  z: "C2",
  s: "C#2",
  x: "D2",
  d: "D#2",
  c: "E2",
  v: "F2",
  g: "F#2",
  b: "G2",
  h: "G#2",
  n: "A2",
  j: "A#2",
  m: "B2",
  ",": "C3",
  q: "C3",
  2: "C#3",
  w: "D3",
  3: "D#3",
  e: "E3",
  r: "F3",
  5: "F#3",
  t: "G3",
  6: "G#3",
  y: "A3",
  7: "A#3",
  u: "B3",
  i: "C4",
  9: "C#4",
  o: "D4",
  0: "D#4",
  p: "E4",
};

const MelodyModal = ({
  instrument,
  onClose,
  bpm,
  setBpm,
  selectedInstruments,
  setSelectedInstruments,
  initialPatternNo,
}) => {
  const { user } = useUser();
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(
    InstrumentAudioContext.getInstVolume(instrument)
  );
  const [tempVolume, setTempVolume] = useState(volume);
  const [selectedPattern, setSelectedPattern] = useState("");
  const [patterns, setPatterns] = useState([]);
  const [grid, setGrid] = useState(
    Array(16)
      .fill()
      .map(() => Array(generateNotes().length).fill(false))
  );
  const intervalRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [activeNotes, setActiveNotes] = useState([]);
  const [currentBar, setCurrentBar] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [octaveOffset, setOctaveOffset] = useState(0);
  const notes = generateNotes();
  const [isVolumeChanging, setIsVolumeChanging] = useState(false);
  const [playingNotes, setPlayingNotes] = useState([]);
  const {
    fetchPianoRollPatterns,
    savePianoRollPattern,
    deletePianoRollPattern,
    fetchNotesPianoRoll,
  } = usePatternInsSeq();

  useEffect(() => {
    const fetchPatterns = async () => {
      const patterns = await fetchPianoRollPatterns();
      setPatterns(patterns);
    };

    fetchPatterns();
  }, [fetchPianoRollPatterns]);

  useEffect(() => {
    if (initialPatternNo) {
      setSelectedPattern(initialPatternNo);
      handlePatternChange({ target: { value: initialPatternNo } });
    }
  }, [initialPatternNo]);

  const debouncedSetVolume = useCallback(
    debounce((newVolume) => {
      InstrumentAudioContext.setInstVolume(newVolume, instrument);
      setIsVolumeChanging(false);
    }, 100),
    []
  );

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setTempVolume(newVolume);
    setIsVolumeChanging(true);
    debouncedSetVolume(newVolume);
  };

  useEffect(() => {
    InstrumentAudioContext.loadAudio(instrument);
    return () => {
      InstrumentAudioContext.stopAllNotes();
    };
  }, [instrument]);

  useEffect(() => {
    if (isPlaying) {
      startPlayback();
    } else {
      InstrumentAudioContext.stopAllNotes();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      startPlayback();
    } else {
      stopPlayback();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      startPlayback();
    }
  }, [bpm]);

  const handlePointerDown = (note) => {
    setIsMouseDown(true);
    playNoteOnPointerDown(note);
    setActiveNotes([note]);
    if (isRecording && !recordingStarted) {
      setRecordingStarted(true);
      startRecording();
    }
    if (isRecording) {
      addNoteToGrid(note, currentBar);
    }
  };

  const handlePointerUp = () => {
    setIsMouseDown(false);
    InstrumentAudioContext.stopAllNotes();
    setActiveNotes([]);
  };

  const handlePointerEnter = (note) => {
    if (isMouseDown) {
      playNoteOnPointerDown(note);
      setActiveNotes((prevNotes) => [...prevNotes, note]);
      if (isRecording) {
        addNoteToGrid(note, currentBar);
      }
    }
  };

  const playNoteOnPointerDown = (note) => {
    const noteIndex = notes.findIndex((n) => n === note);
    InstrumentAudioContext.playNoteOnMouseDown(noteIndex, notes, instrument);
  };

  const handlePatternChange = async (event) => {
    const patternId = event.target.value;
    setSelectedPattern(patternId);

    const notesArray = await fetchNotesPianoRoll(patternId);
    console.log("Retrieved Notes:", notesArray);

    const newGrid = Array(16)
      .fill()
      .map(() => Array(notes.length).fill(false));

    notesArray.forEach((note) => {
      const [noteName, bar] = note.split(",");
      const rowIndex = notes.findIndex((n) => n === noteName);
      const colIndex = parseInt(bar, 10) - 1;
      if (rowIndex !== -1 && colIndex >= 0 && colIndex < 16) {
        newGrid[colIndex][rowIndex] = true;
      }
    });

    setGrid(newGrid);

    if (isPlaying) {
      startPlayback(newGrid);
    }
  };

  const startPlayback = (newGrid = grid) => {
    const totalBars = 16;
    const interval = (60 / bpm) * 250;

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      setCurrentBar((prevBar) => {
        const nextBar = (prevBar + 1) % totalBars;
        const notesForBar = newGrid[nextBar];
        const currentlyPlaying = [];
        notesForBar.forEach((active, noteIndex) => {
          if (active) {
            const note = notes[noteIndex];
            currentlyPlaying.push(note);
            InstrumentAudioContext.playPianoRollNote(
              noteIndex,
              notes,
              instrument
            );
          }
        });
        setPlayingNotes(currentlyPlaying);
        return nextBar;
      });
    }, interval);
  };

  const stopPlayback = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    InstrumentAudioContext.stopAllNotes();
    setPlayingNotes([]);
  };

  const addNoteToGrid = (note, bar) => {
    const newGrid = [...grid];
    const noteIndex = notes.findIndex((n) => n === note);
    newGrid[bar][noteIndex] = true;
    setGrid(newGrid);
  };

  const startRecording = () => {
    setCurrentBar(0);
    const totalBars = 16;
    const interval = (60 / bpm) * 250;

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      setCurrentBar((prevBar) => {
        if (prevBar === totalBars - 1) {
          stopRecording();
          return 0;
        }
        return prevBar + 1;
      });
    }, interval);
  };

  const stopRecording = () => {
    setIsRecording(false);
    setRecordingStarted(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    InstrumentAudioContext.stopAllNotes();
  };

  const getNoteWithOctaveOffset = (note) => {
    const noteName = note.slice(0, -1);
    const octave = parseInt(note.slice(-1), 10) + octaveOffset;
    return `${noteName}${octave}`;
  };

  const handleKeyDown = (e) => {
    const note = keyToNoteMap[e.key];
    if (note) {
      const adjustedNote = getNoteWithOctaveOffset(note);
      if (!playingNotes.includes(adjustedNote)) {
        playNoteOnPointerDown(adjustedNote);
        setPlayingNotes((prevNotes) => [...prevNotes, adjustedNote]);
        if (isRecording) {
          if (!recordingStarted) {
            setRecordingStarted(true);
            startRecording();
          }
          addNoteToGrid(adjustedNote, currentBar);
        }
      }
    }
  };

  const handleKeyUp = (e) => {
    const note = keyToNoteMap[e.key];
    if (note) {
      const adjustedNote = getNoteWithOctaveOffset(note);
      InstrumentAudioContext.stopAllNotes();
      setPlayingNotes((prevNotes) =>
        prevNotes.filter((n) => n !== adjustedNote)
      );
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [playingNotes, isRecording, currentBar]);

  useEffect(() => {
    InstrumentAudioContext.stopAllNotes();
    setPlayingNotes([]);
  }, [octaveOffset]);

  const decreaseOctave = () => {
    setOctaveOffset((prevOffset) => Math.max(prevOffset - 1, -2));
  };

  const increaseOctave = () => {
    setOctaveOffset((prevOffset) => Math.min(prevOffset + 1, 2));
  };

  const renderLEDIndicators = () => {
    const leds = [];
    for (let i = 0; i < 16; i++) {
      leds.push(
        <div
          key={i}
          className={`led1 ${i === currentBar ? "active" : ""}`}
        ></div>
      );
    }
    return leds;
  };

  const renderKey = (note) => {
    const isPlaying = playingNotes.includes(note);
    return (
      <div key={note} className="key-wrapper">
        <div
          className={`key ${isNaturalNote(note) ? "white-key" : "black-key"} ${
            isPlaying ? "highlighted" : ""
          }`}
          data-note={note}
          onPointerDown={() => handlePointerDown(note)}
          onPointerEnter={() => handlePointerEnter(note)}
          onPointerUp={handlePointerUp}
        >
          <div className="note-label">{note}</div>
        </div>
      </div>
    );
  };

  const handleStartRecording = () => {
    setIsRecording(!isRecording);
    if (!isRecording) {
      setRecordingStarted(false);
      setGrid(
        Array(16)
          .fill()
          .map(() => Array(generateNotes().length).fill(false))
      );
    }
  };

  const handleSavePattern = async () => {
    setIsLoadingSave(true);
    try {
      if (selectedPattern) {
        const parsedPatternNo = parseInt(selectedPattern, 10);
        await savePianoRollPattern(
          parsedPatternNo,
          grid,
          notes,
          setSelectedPattern
        );
      } else {
        const maxPatternNo = patterns.reduce(
          (max, pattern) => Math.max(max, pattern.patternNo),
          0
        );
        const newPatternNo = maxPatternNo + 1;
        await savePianoRollPattern(
          newPatternNo,
          grid,
          notes,
          setSelectedPattern
        );
        const newPattern = {
          $id: new Date().getTime().toString(),
          patternNo: newPatternNo,
        };
        setPatterns([...patterns, newPattern]);
        setSelectedPattern(newPatternNo.toString());
      }
    } catch (error) {
      console.error("Failed to save melody pattern:", error);
    } finally {
      setIsLoadingSave(false);
    }
  };

  const handleDeletePattern = async () => {
    const parsedPatternNo = parseInt(selectedPattern, 10);

    if (!parsedPatternNo || parsedPatternNo === 0) {
      alert("Invalid pattern number.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this pattern?")) {
      setIsLoadingDelete(true);
      try {
        await deletePianoRollPattern(parsedPatternNo);
        setSelectedPattern("");

        const updatedInstruments = selectedInstruments.map((instrument) => {
          if (instrument.PianoRollPatternNo === parsedPatternNo) {
            return { ...instrument, PianoRollPatternNo: null };
          }
          return instrument;
        });
        setSelectedInstruments(updatedInstruments);
      } catch (error) {
        console.error("Failed to delete melody pattern:", error);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };

  const debouncedSetBpm = useCallback(
    debounce((newBpm) => {
      setBpm(newBpm);
    }, 100),
    []
  );

  const increaseBpm = () => {
    const newBpm = Math.min(bpm + 5, 300);
    debouncedSetBpm(newBpm);
  };

  const decreaseBpm = () => {
    const newBpm = Math.max(bpm - 5, 20);
    debouncedSetBpm(newBpm);
  };

  const isNaturalNote = (note) => {
    return !note.includes("#");
  };

  const handleClose = () => {
    InstrumentAudioContext.setInstVolume(tempVolume, instrument);
    onClose(selectedPattern);
  };

  const handleStop = () => {
    setIsPlaying(false);
    stopPlayback();
    setCurrentBar(0);
  };

  return (
    <div className="melody-modal">
      <div className="melody-modal-content">
        <div className="melody-modal-header">
          <h2 className="melody-modal-title">{instrument} Keyboard</h2>
          <button onClick={handleClose} className="close-button-melody">
            <FaTimes />
          </button>
        </div>
        <div className="melody-controls">
          <button
            onClick={() => setIsPlaying(!isPlaying)}
            className="play-button-pianoroll"
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button onClick={handleStop} className="stop-button2">
            <FaStop />
          </button>
          <select
            className="roll-pattern-dropdown"
            value={selectedPattern}
            onChange={handlePatternChange}
          >
            <option value="">Select Pattern</option>
            {patterns.map((pattern) => (
              <option key={pattern.$id} value={pattern.patternNo}>
                Preset: {pattern.patternNo}
              </option>
            ))}
          </select>
          <button
            onClick={handleSavePattern}
            className="save-pattern-button"
            disabled={isLoadingSave}
          >
            {isLoadingSave ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              <FaSave />
            )}
          </button>
          <button
            onClick={handleDeletePattern}
            className="stop-button"
            disabled={isLoadingDelete}
          >
            {isLoadingDelete ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              <FaTrash />
            )}
          </button>
        </div>
        <div className="led-indicator"> {renderLEDIndicators()}</div>

        <div className="keyboard-container">
          <div className="keyboard">{notes.map((note) => renderKey(note))}</div>
        </div>
        <div className="option-controls">
          <div className="bpm-controls">
            <button onClick={decreaseBpm} className="bpm-button">
              <FaMinus />
            </button>
            <span className="bpm-display">{bpm} BPM</span>
            <button onClick={increaseBpm} className="bpm-button">
              <FaPlus />
            </button>
          </div>
          <div className="octave-controls">
            <button onClick={decreaseOctave} className="octave-button">
              <FaMinus />
            </button>
            Oct
            <button onClick={increaseOctave} className="octave-button">
              <FaPlus />
            </button>
          </div>
          <button
            onClick={handleStartRecording}
            className={`record-toggle-button ${
              isRecording ? "stop-recording" : "start-recording"
            }`}
          >
            {isRecording ? (
              <FaStopCircle className="icon" />
            ) : (
              <FaMicrophone className="icon" />
            )}
          </button>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={tempVolume}
            onChange={handleVolumeChange}
            className="volume-slider-mel"
          />
        </div>
      </div>
    </div>
  );
};

export default MelodyModal;
