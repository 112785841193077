import React, { useRef, useState } from "react";
import { Databases, Storage, ID } from "appwrite";
import client from "./appwriteConfig";
import "./css/SellTutorials.css";
import { useUser } from "./UserContext";
import { FaPlusCircle } from "react-icons/fa";
import {
  getStorage,
  ref as firebaseRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { app } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
const SellTutorials = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [tutorialTitle, setTutorialTitle] = useState("");
  const [category, setCategory] = useState("");
  const [cost, setCost] = useState("");
  const [duration, setDuration] = useState("");
  const [description, setDescription] = useState("");

  const [videos, setVideos] = useState([{ file: null, name: "" }]);
  const thumbnailInputRef = useRef(null);
  const navigate = useNavigate();
  const handleVideoChange = (index, file) => {
    const updatedVideos = [...videos];
    const fileNameWithoutExtension = file.name
      .split(".")
      .slice(0, -1)
      .join(".");
    updatedVideos[index] = { file, name: fileNameWithoutExtension };
    setVideos(updatedVideos);
  };

  const handleVideoNameChange = (index, name) => {
    const updatedVideos = [...videos];
    updatedVideos[index].name = name;
    setVideos(updatedVideos);
  };

  const addMoreVideos = () => {
    setVideos([...videos, { file: null, name: "" }]);
  };

  const createThumbnail = async (videoFile, videoName) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      const canvasElement = document.createElement("canvas");
      const context = canvasElement.getContext("2d");

      videoElement.src = URL.createObjectURL(videoFile);
      videoElement.currentTime = 1; // Seek to the first second

      videoElement.onseeked = async () => {
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        context.drawImage(
          videoElement,
          0,
          0,
          videoElement.videoWidth,
          videoElement.videoHeight
        );
        const thumbnailBlob = await new Promise((resolve) =>
          canvasElement.toBlob(resolve)
        );
        const thumbnailFileName = `thumbnail_${videoName.split(".")[0]}.png`;
        const thumbnailFile = new File([thumbnailBlob], thumbnailFileName, {
          type: "image/png",
        });

        // Resize the thumbnail to 500px width
        const img = new Image();
        img.src = URL.createObjectURL(thumbnailFile);
        img.onload = async () => {
          const aspectRatio = img.height / img.width;
          const canvas = document.createElement("canvas");
          canvas.width = 500;
          canvas.height = 500 * aspectRatio;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const resizedThumbnailBlob = await new Promise((resolve) =>
            canvas.toBlob(resolve, "image/png")
          );
          const resizedThumbnailFile = new File(
            [resizedThumbnailBlob],
            thumbnailFileName,
            { type: "image/png" }
          );

          // Upload the resized thumbnail file
          const fbStorage = getStorage(app);
          const thumbnailPath = `tutorials/${user.email}/${tutorialTitle}/thumbnail/${thumbnailFileName}`;
          const thumbnailRef = firebaseRef(fbStorage, thumbnailPath);

          // Upload the resized thumbnail file to Firebase Storage
          await uploadBytes(thumbnailRef, resizedThumbnailFile);
          const thumbnailURL = await getDownloadURL(thumbnailRef);
          resolve({ thumbnailURL, thumbnailPath });
        };
      };

      videoElement.onerror = reject;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const fbStorage = getStorage(app);
      const database = new Databases(client);

      const videoDetails = await Promise.all(
        videos.map(async (video, index) => {
          if (video.file) {
            const videoExtension = video.file.name.split(".").pop();
            const videoName = `${tutorialTitle}_${index + 1}_${
              video.name
            }.${videoExtension}`;
            const videoPath = `tutorials/${user.email}/${tutorialTitle}/${videoName}`;
            const videoRef = firebaseRef(fbStorage, videoPath);
            // Upload video file to Firebase Storage
            await uploadBytes(videoRef, video.file);
            const videoURL = await getDownloadURL(videoRef); // Get the URL after uploading

            // Create thumbnail and upload to Appwrite (this remains unchanged)
            const { thumbnailURL, thumbnailPath } = await createThumbnail(
              video.file,
              videoName
            );
            return {
              videoName,
              videoUrl: videoURL,
              videoPath,
              thumbnailUrl: thumbnailURL,
              thumbnailPath,
            };
          }
          return null;
        })
      );
      // Filter out any null video details
      const filteredVideoDetails = videoDetails.filter(
        (detail) => detail !== null
      );

      if (filteredVideoDetails.length === 0) {
        throw new Error("No videos uploaded.");
      }

      // Extract the first video's thumbnailId and videoId
      const firstVideo = filteredVideoDetails[0];

      const tutorialFilesPath = JSON.stringify({
        videoPaths: videoDetails.map((detail) => detail.videoPath),
        thumbnailPaths: videoDetails.map((detail) => detail.thumbnailPath),
      });
      // Log the thumbnailId and videoId of the first video
      // console.log("First Video Thumbnail ID:", firstThumbnailId);
      // console.log("First Video ID:", firstVideoId);

      // Convert the videos array to a JSON string
      const videosJsonString = JSON.stringify(filteredVideoDetails);

      // Create a document with the video information
      const email = user.email;
      const tutorialId = ID.unique();
      const tutorialData = {
        authorName,
        tutorialTitle,
        category,
        duration,
        cost: parseInt(cost, 10),
        description,
        videoUrl: firstVideo.videoUrl, // Use first video's videoId
        thumbnailUrl: firstVideo.thumbnailUrl, // Save only the thumbnailId as thumbnailUrl
        videos: videosJsonString, // Save videos as JSON string
        email,
        thumbnailPath: firstVideo.thumbnailPath,
        noOfVideos: filteredVideoDetails.length, // Save the number of videos
        tutorialFilesPaths: tutorialFilesPath,
      };

      const response = await database.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
        tutorialId,
        tutorialData
      );
      console.log(response);

      setLoading(false);
      setAuthorName("");
      setTutorialTitle("");
      setCategory("");
      setDuration("");
      setCost("");
      setDescription("");
      setVideos([{ file: null, name: "" }]);
      if (thumbnailInputRef.current) thumbnailInputRef.current.value = null;
      navigate("/");
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Error uploading files");
      setLoading(false);
    }
  };

  return (
    <div className="sell-tutorials-container">
      <h2>Sell Your Tutorials</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Author Name:</label>
          <input
            type="text"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Tutorial Title:</label>
          <input
            type="text"
            value={tutorialTitle}
            onChange={(e) => setTutorialTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Category:</label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Duration:</label>
          <input
            type="text"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Cost:</label>
          <input
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Upload Video Files:</label>
          {videos.map((video, index) => (
            <div key={index} className="video-input-group">
              <input
                type="file"
                accept="video/*"
                onChange={(e) => handleVideoChange(index, e.target.files[0])}
                required
              />
              <input
                type="text"
                placeholder="Video Name"
                value={video.name}
                onChange={(e) => handleVideoNameChange(index, e.target.value)}
                required
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addMoreVideos}
            className="add-more-videos-button"
          >
            <FaPlusCircle /> Add More Videos
          </button>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Submit"}
        </button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default SellTutorials;
