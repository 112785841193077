const customStyles = () => {
  const isSmallScreen = window.innerWidth < 458; // Check if the screen width is less than 458px
  const smallFontSize = "10px"; // Font size for small screens
  const normalFontSize = "14px"; // Default font size

  return {
    control: (provided, state) => ({
      ...provided,
      width: "fit-content",
      margin: "0 auto",
      marginBottom: "10px",
      backgroundColor: "#333",
      borderColor: state.isFocused ? "#666" : "#444",
      boxShadow: state.isFocused ? "0 0 0 1px #666" : "none",
      zIndex: 7,
      "&:hover": {
        borderColor: state.isFocused ? "#666" : "#555",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      marginTop: 0,
      backgroundColor: "#222",
      color: "white",
      border: "1px solid #555",
      zIndex: 7,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 7 }),
    option: (provided, state) => ({
      ...provided,
      fontSize: isSmallScreen ? smallFontSize : normalFontSize,
      backgroundColor: state.isSelected
        ? "#555"
        : state.isFocused
        ? "#333"
        : "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "#555",
        color: "white",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#555",
      fontSize: isSmallScreen ? smallFontSize : normalFontSize,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "white",
      fontSize: isSmallScreen ? smallFontSize : normalFontSize,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#999",
      fontSize: isSmallScreen ? smallFontSize : normalFontSize,
      "&:hover": {
        backgroundColor: "#777",
        color: "white",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#bbb",
      fontSize: isSmallScreen ? smallFontSize : normalFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
      fontSize: isSmallScreen ? smallFontSize : normalFontSize,
    }),
  };
};

export default customStyles;
