import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "../UserContext";
import client from "../appwriteConfig";
import { Databases, Query } from "appwrite";

const databases = new Databases(client);

const PrivateRoom = () => {
  const { user } = useUser();
  const location = useLocation();
  const roomId = location.state?.roomId;
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const databaseId = process.env.REACT_APP_DATABASE_ID;
  const collectionId = process.env.REACT_APP_COLLECTION_MESSAGES;

  // Fetch messages when component mounts and subscribe to realtime updates
  useEffect(() => {
    if (roomId) {
      fetchMessages(roomId);
      const unsubscribe = client.subscribe(
        `databases.${databaseId}.collections.${collectionId}.documents`,
        (response) => {
          if (
            response.events.includes(
              "databases.*.collections.*.documents.*.update"
            )
          ) {
            console.log("Realtime update event:", response);
            const updatedMessages = JSON.parse(response.payload.messages);
            setMessages(updatedMessages);
          } else if (
            response.events.includes(
              "databases.*.collections.*.documents.*.create"
            )
          ) {
            console.log("Realtime update event:", response);
            const updatedMessages = JSON.parse(response.payload.messages);
            setMessages(updatedMessages);
          }
        }
      );
      return () => unsubscribe();
    }
  }, [roomId]);

  const fetchMessages = async (roomId) => {
    try {
      const result = await databases.listDocuments(databaseId, collectionId, [
        Query.equal("roomId", roomId),
      ]);
      if (result.documents.length > 0) {
        const messagesArray = JSON.parse(result.documents[0].messages);
        setMessages(messagesArray);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim() !== "") {
      try {
        let currentDocument;
        try {
          currentDocument = await databases.getDocument(
            databaseId,
            collectionId,
            roomId
          );
          currentDocument.messages = JSON.parse(currentDocument.messages); // Parse JSON string to array
        } catch (error) {
          console.log("No document found for room, creating new one");
        }

        const newMessage = {
          senderEmail: user?.email,
          content: message,
          timestamp: new Date().toISOString(),
        };

        if (currentDocument) {
          const updatedMessages = [...currentDocument.messages, newMessage];
          await databases.updateDocument(databaseId, collectionId, roomId, {
            messages: JSON.stringify(updatedMessages),
          });
        } else {
          await databases.createDocument(databaseId, collectionId, roomId, {
            roomId: roomId,
            messages: JSON.stringify([newMessage]),
          });
        }
        setMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <div>
      <h2>Private Room Chat</h2>
      {user ? (
        <div className="user-info">
          <p>
            <strong>Name:</strong> {user.name || "Anonymous"}
          </p>
          <p>
            <strong>Email:</strong> {user.email || "No email provided"}
          </p>
        </div>
      ) : (
        <p>No user information available</p>
      )}
      <div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button onClick={handleSendMessage}>Send Message</button>
      </div>
      <div>
        <h2>Messages:</h2>
        <ul>
          {messages.map((msg) => (
            <li key={msg.timestamp + msg.senderEmail}>
              <strong>
                {new Date(msg.timestamp).toLocaleString()} ({msg.senderEmail}):
              </strong>{" "}
              {msg.content}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PrivateRoom;
