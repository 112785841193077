// moveAppwrite.js
import { ID, Databases } from "appwrite"; // Import Appwrite SDK
import client from "../appwriteConfig";

// Function to save the initial game details
export const saveGameDetailsToAppwrite = async (gameDetails) => {
  const databases = new Databases(client);
  try {
    const response = await databases.createDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_CHESSGAMES, // Replace with your actual collection ID
      ID.unique(), // Generate a unique document ID
      gameDetails // Game details passed as a parameter
    );
    console.log("Game details saved:", response);
    return response.$id; // Return the generated game ID
  } catch (error) {
    console.error("Error saving game details:", error);
  }
};

// Function to save each move in proper chess notation and append to existing moves
export const saveMoveToAppwrite = async (
  gameId,
  moveData, // Now passing moveData object which includes 'move' and 'color'
  updatedBoardState
) => {
  const databases = new Databases(client);

  try {
    // Get the existing game document from the database
    const gameDocument = await databases.getDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_CHESSGAMES,
      gameId
    );

    // Initialize whiteMoves and blackMoves if they don't exist
    let whiteMoves = gameDocument.whiteMoves
      ? JSON.parse(gameDocument.whiteMoves)
      : [];
    let blackMoves = gameDocument.blackMoves
      ? JSON.parse(gameDocument.blackMoves)
      : [];

    // Append the new move to the correct color array
    if (moveData.color === "white") {
      whiteMoves.push(moveData.move);
    } else {
      blackMoves.push(moveData.move);
    }

    // Update the document with the new moves arrays
    const response = await databases.updateDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_CHESSGAMES,
      gameId,
      {
        boardState: JSON.stringify(updatedBoardState), // Ensure board state is updated
        whiteMoves: JSON.stringify(whiteMoves), // Save white moves as a JSON string
        blackMoves: JSON.stringify(blackMoves), // Save black moves as a JSON string
      }
    );

    console.log("Move saved:", response);
  } catch (error) {
    console.error("Error saving move:", error);
  }
};
