import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
// Import the CSS file
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <footer>
      <div className="footer-hxabyte">
        {" "}
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Hxabyte Records. All rights
          reserved.
        </p>
        <div className="footer-nav">
          <Link to="/terms" className="footer-text">
            Terms & Conditions
          </Link>
          <Link to="/contact-us" className="footer-text">
            Contact Us
          </Link>
          <Link to="/refund" className="footer-text">
            Refund Policy
          </Link>
          <Link to="/privacy-policy" className="footer-text">
            Privacy Policy
          </Link>
        </div>{" "}
        <div className="footer-text-creator">
          <a
            href="https://www.instagram.com/hxabyte" // Replace with your Instagram profile link
            className="footer-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.youtube.com/@lenix" // Replace with your YouTube channel link
            className="footer-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>{" "}
          <a
            href="https://www.linkedin.com/in/lenix-hxabyte/" // Replace with your LinkedIn profile link
            className="footer-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>{" "}
      </div>
    </footer>
  );
};

export default Footer;
