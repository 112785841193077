import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import client from "./appwriteConfig";
import { FaShoppingCart, FaPlay, FaPause } from "react-icons/fa";
import { useUser } from "./UserContext"; // Make sure this hook is implemented correctly
import { Databases, Query } from "appwrite";
import "./css/SeperateBeat.css";
import { BeatLoader, ClipLoader } from "react-spinners";
import { BsPauseFill, BsPlayFill } from "react-icons/bs";
import ShareButton from "./ShareButton";
function SeparateBeat() {
  const { beatName } = useParams();
  const [beatInfo, setBeatInfo] = useState(null);
  const audioRef = useRef(new Audio());
  const [cartLoading, setCartLoading] = useState({});
  const {
    addToCart,
    cart,
    fetchCartData,
    user,
    currentTrack,
    setCurrentTrack,
    isPlaying,
    togglePlay,
  } = useUser();

  useEffect(() => {
    const fetchBeatInfo = async () => {
      try {
        const database = new Databases(client, "default");
        const sanitizedTitle = beatName.replace(/-/g, " ");
        const { documents } = await database.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID,
          [Query.equal("beatName", sanitizedTitle)]
        );
        if (documents.length > 0) {
          setBeatInfo(documents[0]);
          audioRef.current.src = documents[0].lowAudioUrl; // Assuming 'lowAudioUrl' is the correct key
        }
      } catch (error) {
        console.error("Failed to fetch beat info", error);
      }
    };

    fetchBeatInfo();
  }, [beatName]);

  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, [user]);

  const isInCart = (productId) =>
    cart.some((item) => item.productId === productId);

  const handleAddToCart = (product) => {
    if (!isInCart(product.$id)) {
      const productWithType = {
        ...product,
        productType: "beat", // Add productType here
      };
      addToCart(productWithType); // Pass the product with productType set
    }
  };
  const handlePlayPause = (product) => {
    if (currentTrack && currentTrack.$id === product.$id) {
      togglePlay();
    } else {
      setCurrentTrack(product);
      if (!isPlaying) {
        togglePlay();
      }
    }
  };
  if (!beatInfo)
    return (
      <div>
        {" "}
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <BeatLoader color="#3498db" />
        </div>
      </div>
    );

  return (
    <div className="separate-beat-container">
      <div className="seperate-title">
        {" "}
        <h1>{beatInfo.beatName}</h1>
        <ShareButton />
      </div>

      <div className="beat-image-container" onClick={togglePlay}>
        <img src={beatInfo.imageUrl} alt={beatInfo.beatName} />
        <button
          onClick={() => handlePlayPause(beatInfo)}
          className="play-button-home"
        >
          {currentTrack && currentTrack.$id === beatInfo.$id && isPlaying ? (
            <BsPauseFill />
          ) : (
            <BsPlayFill />
          )}
        </button>
      </div>
      <div className="beatinfo-details">
        <p>
          {" "}
          <label>Artist:</label> {beatInfo.artistName}
        </p>

        <p>
          <label> Tempo:</label> {beatInfo.tempo} bpm
        </p>
        <p>
          <label> Cost:</label> ₹{beatInfo.cost}
        </p>
        <p>
          <label> Genre:</label> {beatInfo.genre}
        </p>
      </div>

      <button
        className="addCart-button-indv"
        onClick={() => handleAddToCart(beatInfo)}
        disabled={isInCart(beatInfo.$id)}
      >
        {/* <div className="product-info cost">{`₹${beatInfo.cost}`}</div> */}
        <div className="cartbtn-add">
          <div className="product-info cost">{`₹${beatInfo.cost}`}</div>
          {cartLoading[beatInfo.$id] ? (
            <>
              <ClipLoader size={7} color="#FFF" />
              {"   "}
            </>
          ) : isInCart(beatInfo.$id) ? (
            <> In cart </>
          ) : (
            <> Add to cart </>
          )}
          <FaShoppingCart />
        </div>
      </button>
    </div>
  );
}

export default SeparateBeat;
