import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import { Databases, Query, Storage } from "appwrite";
import client from "./appwriteConfig";
import { v4 as uuidv4 } from "uuid";
import "./css/Home.css";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { BeatLoader } from "react-spinners";
import { FaShoppingCart } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import genres from "./genreBeat";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select from "react-select";
import customStyles from "./css/StylesForSelect";
function Home() {
  const {
    user,
    cart,
    addToCart,
    fetchCartData,
    currentTrack,
    setCurrentTrack,
    isPlaying,
    togglePlay,
    cartLoading,
  } = useUser();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [selectedGenres, setSelectedGenres] = useState([]);
  const genreOptions = genres.map((genre) => ({ value: genre, label: genre }));
  const styles = customStyles();
  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, [user]);

  useEffect(() => {
    const fetchProducts = async () => {
      const database = new Databases(client, "default");
      try {
        const { documents } = await database.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID,
          [Query.limit(1000)]
        );
        setProducts(documents);
        setLoading(false);
        // console.log(documents);
        // console.log("User after phone login:", user);
      } catch (error) {
        console.error("Failed to fetch products", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const isInCart = (productId) =>
    cart.some((item) => item.productId === productId);

  const handlePlayPause = (product) => {
    if (currentTrack && currentTrack.$id === product.$id) {
      togglePlay();
    } else {
      setCurrentTrack(product);
      if (!isPlaying) {
        togglePlay();
      }
    }
  };
  const handleGenreChange = (selectedOptions) => {
    setSelectedGenres(selectedOptions || []);
  };

  const sortProducts = (a, b) => {
    if (sortBy === "cost-descending") {
      return b.cost - a.cost;
    } else if (sortBy === "cost-ascending") {
      return a.cost - b.cost;
    } else if (sortBy === "tempo-descending") {
      return b.tempo - a.tempo;
    } else if (sortBy === "tempo-ascending") {
      return a.tempo - b.tempo;
    } else {
      return 0;
    }
  };
  const filteredAndSortedProducts = products
    .filter(
      (product) =>
        selectedGenres.length === 0 ||
        selectedGenres.some((genre) => product.genre.includes(genre.value))
    )
    .sort(sortProducts);
  const handleAddToCart = (product) => {
    if (!isInCart(product.$id)) {
      const productWithType = {
        ...product,
        productType: "beat", // Add productType here
      };
      addToCart(productWithType); // Pass the product with productType set
    }
  };

  return (
    <div className="document-list-container">
      <div className="home-container">
        <h2> 🎵 Discover Fresh Beats Daily! </h2>
      </div>
      <div className="beat-products">
        <div className="sorting-header">
          <Select
            isMulti
            closeMenuOnSelect={false}
            options={genreOptions}
            onChange={handleGenreChange}
            value={selectedGenres}
            placeholder="Filter by Genres"
            classNamePrefix="filter-dropdown"
            className="filter-dropdown"
            styles={styles}
            // Adjusts the menu placement dynamically
            // Use 'fixed' to help with viewport overflow issues
            menuPortalTarget={document.body} // Render menu within the body DOM as a portal
          />
          <select
            className="sorting-dropdown"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="">Sort by...</option>
            <option value="cost-descending">Cost: High to Low</option>
            <option value="cost-ascending">Cost: Low to High</option>
            <option value="tempo-descending">Tempo: High to Low</option>
            <option value="tempo-ascending">Tempo: Low to High</option>
          </select>
        </div>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <BeatLoader color="#3498db" />
          </div>
        ) : products.length > 0 ? (
          <div className="document-grid">
            {filteredAndSortedProducts.map((product) => (
              <div key={uuidv4()} className="product-row">
                <div className="product-image-beats">
                  <Link
                    to={`/beats/${product.beatName
                      .replace(/\s+/g, "-")
                      .toLowerCase()}`}
                  >
                    <img src={product.imageUrl} alt="Product" />
                  </Link>
                  <button
                    onClick={() => handlePlayPause(product)}
                    className="play-button-home"
                  >
                    {currentTrack &&
                    currentTrack.$id === product.$id &&
                    isPlaying ? (
                      <BsPauseFill />
                    ) : (
                      <BsPlayFill />
                    )}
                  </button>
                </div>
                <div className="artist-beat-info">
                  <div className="product-info beat-name">
                    <Link
                      to={`/beats/${product.beatName
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      <div className="beat-name-link">{product.beatName}</div>
                    </Link>
                  </div>
                  <div className="product-info artist-name">
                    {product.artistName}
                  </div>
                  <div className="product-genre">{product.genre}</div>{" "}
                  <div className="product-info">{product.tempo} bpm</div>
                </div>
                <div className="cost-cart-info">
                  <button
                    className="addCart-button"
                    onClick={() => handleAddToCart(product)}
                    disabled={isInCart(product.$id)}
                  >
                    <div className="product-info cost">{`₹${product.cost}`}</div>
                    <div className="cartbtn-add">
                      {cartLoading[product.$id] ? (
                        <>
                          <ClipLoader size={7} color="#FFF" />
                          {"   "}
                        </>
                      ) : isInCart(product.$id) ? (
                        <> In </>
                      ) : (
                        <> Add </>
                      )}
                      <FaShoppingCart />
                    </div>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No products found.</p>
        )}{" "}
      </div>
    </div>
  );
}

export default Home;
