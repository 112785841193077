// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrNFT8mVf43ymtj7c9bh0Vkp6XcRRsVmI",
  authDomain: "hxabyte-fire.firebaseapp.com",
  projectId: "hxabyte-fire",
  storageBucket: "hxabyte-fire.firebasestorage.app",
  messagingSenderId: "471372614912",
  appId: "1:471372614912:web:1c94a0e1fec1c7ac4b77e5",
  measurementId: "G-HJXPXLZ67Q",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);
