import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Databases, Query } from "appwrite";
import client from "./appwriteConfig";
import { useUser } from "./UserContext";
import "./css/SoldProducts.css";

const databases = new Databases(client);

const SoldProducts = () => {
  const [soldItems, setSoldItems] = useState([]);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const fetchSoldItems = async () => {
        try {
          const response = await databases.listDocuments(
            process.env.REACT_APP_DATABASE_ID,
            process.env.REACT_APP_COLLECTION_TRANSACTIONS,
            [
              Query.orderDesc("$createdAt"),
              Query.equal("paymentStatus", "success"),
              Query.limit(300),
            ]
          );

          const filteredItems = response.documents.filter((item) => {
            try {
              const cartItems = JSON.parse(item.cart);
              return cartItems.some(
                (cartItem) => cartItem.sellerEmail === user.email
              );
            } catch (error) {
              console.error("Failed to parse cart:", error);
              return false;
            }
          });

          setSoldItems(filteredItems);
          calculateMonthlyEarnings(filteredItems, selectedMonth, selectedYear);
        } catch (error) {
          console.error("Failed to fetch sold items", error);
        }
      };
      fetchSoldItems();
    } else {
      console.log("No user logged in.");
    }
  }, [user, selectedMonth, selectedYear]);

  const calculateMonthlyEarnings = (items, month, year) => {
    const earnings = items.reduce((sum, item) => {
      const itemDate = new Date(item.$createdAt);
      if (
        itemDate.getMonth() === month &&
        itemDate.getFullYear() === year &&
        item.paymentStatus === "success"
      ) {
        const cartItems = JSON.parse(item.cart).filter(
          (cartItem) => cartItem.sellerEmail === user.email
        );
        return (
          sum +
          cartItems.reduce(
            (innerSum, cartItem) => innerSum + (cartItem.sellerCost || 0),
            0
          )
        );
      }
      return sum;
    }, 0);

    setMonthlyEarnings(earnings);
  };

  const generateMonthOptions = () => {
    const options = [];
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    for (let year = currentYear; year >= currentYear - 5; year--) {
      for (let month = 11; month >= 0; month--) {
        if (year === currentYear && month > currentMonth) continue;
        const date = new Date(year, month);
        options.push(
          <option key={`${month}-${year}`} value={`${month}-${year}`}>
            {date.toLocaleString("default", { month: "long" })} {year}
          </option>
        );
      }
    }
    return options;
  };

  const handleMonthChange = (event) => {
    const [month, year] = event.target.value.split("-").map(Number);
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const navigateBack = () => navigate("/your-products");

  const filteredSoldItems = soldItems.filter((item) => {
    const itemDate = new Date(item.$createdAt);
    return (
      itemDate.getMonth() === selectedMonth &&
      itemDate.getFullYear() === selectedYear
    );
  });

  return (
    <div className="sold-prod-container">
      <div className="sold-prod-title">
        <h2>Sold Products </h2>
        <button onClick={navigateBack} className="sold-button">
          Go Back
        </button>
      </div>

      <div className="monthly-summary">
        <div className="month-sold-header">
          <label htmlFor="month-select">Select Month:</label>
          <select id="month-select" onChange={handleMonthChange}>
            {generateMonthOptions()}
          </select>
        </div>

        <h3>Monthly Earnings: ₹{monthlyEarnings.toFixed(2)}</h3>
      </div>

      <div className="sold-items">
        {filteredSoldItems.length === 0 ? (
          <p>No sold products found for the selected month and year.</p>
        ) : (
          filteredSoldItems.map((item) => {
            const cartItems = JSON.parse(item.cart).filter(
              (cartItem) => cartItem.sellerEmail === user.email
            );

            const totalCostSum = cartItems.reduce(
              (sum, cartItem) => sum + cartItem.totalCost,
              0
            );
            const platformFeeSum = cartItems.reduce(
              (sum, cartItem) => sum + cartItem.platformFee,
              0
            );
            const sellerCostSum = cartItems.reduce(
              (sum, cartItem) => sum + cartItem.sellerCost,
              0
            );

            return (
              <div className="sold-prod-items" key={item.$id}>
                <div className="transaction-info">
                  <p>
                    <strong>Transaction ID:</strong> {item.merchTransID}
                  </p>
                  <p>
                    <strong>Purchase ID:</strong> {item.payUTransID}
                  </p>
                  <p>
                    <strong>Total Price:</strong> ₹{item.totalPrice}
                  </p>
                  <p>
                    <strong>Purchase Date:</strong>{" "}
                    {new Date(item.$createdAt).toLocaleString()}
                  </p>
                  {/* <strong>{item.paymentStatus}</strong> */}
                  <p>
                    <strong>Customer Name:</strong> {item.CustomerName}
                  </p>
                  <p>
                    <strong>Customer Email:</strong> {item.CustomerEmail}
                  </p>
                  <p>
                    <strong>Mobile:</strong> {item.mobileNumber}
                  </p>
                  {item.CustomerAddress?.trim() && (
                    <p>
                      <strong>Customer Address:</strong> {item.CustomerAddress}
                    </p>
                  )}
                </div>

                <table className="sold-prod-table">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Product Type</th>
                      <th>Quantity</th>
                      <th>Cost</th>
                      <th>Total Cost</th>
                      <th>Platform Fee</th>
                      <th>Seller Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((cartItem, index) => (
                      <tr key={index}>
                        <td>{cartItem.productName}</td>
                        <td>{cartItem.productType}</td>
                        <td>
                          {cartItem.productType === "fashion"
                            ? cartItem.quantity
                            : "-"}
                        </td>
                        <td>₹{cartItem.cost}</td>
                        <td>₹{cartItem.totalCost}</td>
                        <td>₹{cartItem.platformFee}</td>
                        <td>₹{cartItem.sellerCost}</td>
                      </tr>
                    ))}
                    <tr className="totals-row">
                      <td colSpan="4">
                        <strong>Transaction Totals:</strong>
                      </td>
                      <td>
                        <strong>₹{totalCostSum.toFixed(2)}</strong>
                      </td>
                      <td>
                        <strong>₹{platformFeeSum.toFixed(2)}</strong>
                      </td>
                      <td>
                        <strong>₹{sellerCostSum.toFixed(2)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SoldProducts;
