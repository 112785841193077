import React from "react";
import "./css/ConfirmModal.css"; // Ensure the CSS is imported

const ConfirmModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-modal-background">
      <div className="confirm-modal-content">
        <h2 className="confirm-modal-header">
          Do you really want to delete it?
        </h2>
        <div>
          <button onClick={onConfirm} className="confirm-modal-button">
            Yes
          </button>
          <button onClick={onClose} className="confirm-modal-button">
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
