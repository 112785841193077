// genreBeat.js
const genres = [
  "Hip Hop",
  "Trap",
  "UK Drill",
  "Gangsta Rap",
  "Old School Hip Hop",
  "Cloud Rap",
  "Boom Bap",
  "Jazz Rap",
  "RnB",
  "Contemporary R&B",
  "Pop",
  "Synth Pop",
  "K-Pop",
  "Electropop",
  "Indie Pop",
  "House",
  "Deep House",
  "Tech House",
  "Progressive House",
  "EDM",
  "Dubstep",
  "Drum & Bass",
  "Trance",
  "Hardstyle",
  "Funk",
  "Disco Funk",
  "G-Funk",
  "Psychedelic Funk",
  "Drake Type",
  "Kendrick Type",
  "Travis Scott Type",
  "Eminem Type",
  "J Cole Type",
  "Nas Type",
  "Jay Z Type",
  "Carnatic",
  "Hindustani Classical",
  "Indian Folk",
  "Bollywood Contemporary",
  "Tamil Film Music",
  "Anirudh Type",
  "ARR Type",
  "HiphopTamizha Type",
  "Rap Tamil",
  "Telugu Film Music",
  "Kannada Film Music",
  "Malayalam Film Music",
  "Punjabi Bhangra",
  "Ghazal",
  "Qawwali",
  "Sufi Music",
  "Indian Pop (Indipop)",
  "Rajasthani Folk",
  "Gujarati Folk",
  "Psychedelic",
  "Psytrance",
  "Psychedelic Rock",
  "Ambient",
  "Chillout",
  "Lofi Hip Hop",
  "Vaporwave",
  "Future Bass",
  "Chillwave",
  "Synthwave",
  "Glitch Hop",
  "Moombahton",
  "Reggaeton",
  "Latin Trap",
  "Afrobeat",
  "Dancehall",
  "Reggae",
  "Ska",
  "Rock",
  "Classic Rock",
  "Alternative Rock",
  "Punk Rock",
  "Metal",
  "Heavy Metal",
  "Death Metal",
  "Black Metal",
  "Blues",
  "Delta Blues",
  "Chicago Blues",
  "Country",
  "Classic Country",
  "Modern Country",
  "Bluegrass",
  "Folk",
  "American Folk",
  "Celtic Folk",
  // Continue adding genres as needed
];

export default genres;
