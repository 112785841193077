import React, { useState } from "react";
import { Storage, Databases, ID } from "appwrite";
import client from "./appwriteConfig";
import "./css/KitModal.css";
import { ClipLoader } from "react-spinners";

function KitModal({ onClose, refreshPads }) {
  const [kitName, setKitName] = useState("");
  const [kitId, setKitId] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file || !kitName || !kitId) {
      alert("Please provide a kit name, kit ID, and select an audio file.");
      return;
    }

    if (kitId.includes(" ")) {
      setError("Kit ID cannot contain spaces.");
      return;
    }

    setLoading(true);
    try {
      const storage = new Storage(client);
      const audioResponse = await storage.createFile(
        process.env.REACT_APP_BUCKET_KIT_ID,
        ID.unique(),
        file
      );
      const kitUrl = audioResponse.$id;

      const database = new Databases(client);
      const response = await database.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_KIT_ID,
        ID.unique(), // Ensure unique ID for the document
        { kitName, kitUrl: kitUrl, kitId }
      );

      console.log("Document created:", response);
      refreshPads();
      onClose();
    } catch (error) {
      console.error("Error creating kit:", error);
      setError("Failed to create kit");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="kit-modal" onClick={onClose}>
      <div className="kit-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="kit-close" onClick={onClose}>
          &times;
        </span>
        <form onSubmit={handleSubmit}>
          <label>
            Kit Name:
            <input
              type="text"
              value={kitName}
              onChange={(e) => setKitName(e.target.value)}
              required
            />
          </label>
          <label>
            Kit ID:
            <input
              type="text"
              value={kitId}
              onChange={(e) => setKitId(e.target.value)}
              placeholder="Spaces are not allowed"
              required
            />
          </label>
          <label>
            Upload Audio:
            <input
              type="file"
              onChange={handleFileChange}
              accept="audio/*"
              required
            />
          </label>
          <button type="submit" disabled={loading}>
            {loading ? (
              <div className="uploading">
                <ClipLoader size={10} color="#FFF" />
              </div>
            ) : (
              "Submit"
            )}
          </button>
          {error && <div className="kit-error">{error}</div>}
        </form>
      </div>
    </div>
  );
}

export default KitModal;
