import React, { useEffect, useState } from "react";
import { Databases, Query, Storage } from "appwrite";
import client from "./appwriteConfig";
import "./css/Fashion.css";
import { BeatLoader } from "react-spinners";
import { useUser } from "./UserContext";
import { FaShoppingCart } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";

const databases = new Databases(client);
const storage = new Storage(client);

const Fashion = () => {
  const { user, cart, addToCart, cartLoading, fetchCartData } = useUser();
  const { brandName } = useParams(); // ✅ Get brand name from URL
  const [items, setItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalImage, setModalImage] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, [user]);

  useEffect(() => {
    fetchAllItems(); // ✅ Always fetch all brands to keep the sidebar intact
  }, []);

  useEffect(() => {
    if (brandName) {
      fetchBrandItems(brandName); // ✅ Fetch only selected brand's items
    } else {
      setFilteredItems(items); // ✅ Show all items when no brand is selected
    }
  }, [brandName, items]);

  const fetchAllItems = async () => {
    try {
      setLoading(true);
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_FASHION_ID,
        [Query.limit(1000)]
      );

      const fetchedItems = await Promise.all(
        response.documents.map(async (item) => {
          const fileView = await storage.getFileView(
            process.env.REACT_APP_BUCKET_FASHION_ID,
            item.fashionUrl
          );
          return {
            ...item,
            imageUrl: fileView.href,
            inCart: cart.some((cartItem) => cartItem.productId === item.$id),
          };
        })
      );

      const groupedItems = groupItemsByBrand(fetchedItems);
      setItems(groupedItems); // ✅ Keep all brands for the sidebar
      setFilteredItems(groupedItems); // ✅ Display all items initially
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch items", error);
      setLoading(false);
    }
  };

  const fetchBrandItems = async (brand) => {
    if (!items[brand]) return; // ✅ Prevent unnecessary fetching if we already have the data

    setFilteredItems({ [brand]: items[brand] }); // ✅ Filter from existing items instead of overwriting
    // console.log(`Filtered items for brand "${brand}":`, items[brand]);
  };

  const groupItemsByBrand = (items) => {
    return items.reduce((groups, item) => {
      const brand = item.brand;
      if (!groups[brand]) {
        groups[brand] = [];
      }
      groups[brand].push(item);
      return groups;
    }, {});
  };

  const isInCart = (productId) =>
    cart.some((item) => item.productId === productId);

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
  };

  const handleCloseModal = () => {
    setModalImage(null);
  };

  const handleAddClick = (item) => {
    const currentQuantity = quantities[item.$id] || 1;
    if (currentQuantity >= 1 && currentQuantity <= item.stock) {
      const fashionInfo = JSON.stringify({
        fashionUrl: item.fashionUrl,
        cost: item.cost,
        quantity: currentQuantity,
      });

      addToCart(
        {
          ...item,
          FASHIONinfo: fashionInfo, // Consolidate info into FASHIONinfo
          productType: "fashion", // Set productType to fashion
        },
        currentQuantity
      );
      setQuantities((prev) => ({ ...prev, [item.$id]: 1 })); // Reset quantity to 1 after adding to cart
    } else {
      alert("Invalid quantity.");
    }
  };

  const handleQuantityChange = (itemId, change, stock) => {
    const currentQuantity = quantities[itemId] || 1;
    let newQuantity = currentQuantity + change;
    if (newQuantity < 1) newQuantity = 1;
    if (newQuantity > stock) newQuantity = stock;
    setQuantities((prev) => ({ ...prev, [itemId]: newQuantity }));
  };

  return (
    <div className="fashion-container">
      <div className="fashion-header">
        <button onClick={() => navigate("/fashion")}>
          <h2> Fashion & Merchandise</h2>
        </button>
      </div>

      <div className="fashion-contain">
        <div className="fashion-sidebar">
          <p>Brands</p>
          {Object.keys(items).map((brand) => (
            <h4
              key={brand}
              onClick={() => navigate(`/fashion/${brand}`)}
              className={`fashion-brand-button ${
                brandName === brand ? "active-brand" : ""
              }`}
            >
              {brand}
            </h4>
          ))}
        </div>

        <div className="fashion-content">
          {loading ? (
            <div style={{ textAlign: "center" }} className="loader-fashion">
              <BeatLoader color="#3498db" />
            </div>
          ) : brandName && items[brandName] ? (
            // ✅ Show only the selected brand's products
            <div className="brand-section">
              <h3 className="brand-header">{brandName}</h3>
              <div className="fashion-grid">
                {items[brandName].map((item) => (
                  <div key={item.$id} className="fashion-item">
                    <img
                      src={item.imageUrl}
                      alt={item.itemName}
                      className="fashion-image"
                      onClick={() => handleImageClick(item.imageUrl)}
                    />
                    <div className="fashion-details">
                      <b> ₹ {item.cost}</b>
                      <h3>{item.itemName}</h3>
                      <p> {item.category}</p>
                      <p>Sizes: {item.sizes}</p>
                      <p> {item.colors}</p>
                      <p>Material: {item.material}</p>

                      <div className="fashion-counter">
                        <button
                          className="addCart-button-f"
                          onClick={() => handleAddClick(item)}
                          disabled={isInCart(item.$id)}
                        >
                          {cartLoading[item.$id] ? (
                            <>
                              <ClipLoader size={10} color="#FFF" />
                              {"   "}
                            </>
                          ) : isInCart(item.$id) ? (
                            "In "
                          ) : (
                            "Add "
                          )}
                          <FaShoppingCart />
                        </button>
                        <button
                          className="counter-button"
                          onClick={() =>
                            handleQuantityChange(item.$id, -1, item.stock)
                          }
                        >
                          -
                        </button>
                        <span className="counter-number">
                          {quantities[item.$id] || 1}
                        </span>
                        <button
                          className="counter-button"
                          onClick={() =>
                            handleQuantityChange(item.$id, 1, item.stock)
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : !brandName ? (
            // ✅ If no brand is selected, show all brands
            Object.keys(items).length > 0 ? (
              Object.keys(items).map((brand) => (
                <div key={brand} className="brand-section">
                  <h3 className="brand-header">{brand}</h3>
                  <div className="fashion-grid">
                    {items[brand].map((item) => (
                      <div key={item.$id} className="fashion-item">
                        <img
                          src={item.imageUrl}
                          alt={item.itemName}
                          className="fashion-image"
                          onClick={() => handleImageClick(item.imageUrl)}
                        />
                        <div className="fashion-details">
                          <b> ₹ {item.cost}</b>
                          <h3>{item.itemName}</h3>
                          <p> {item.category}</p>
                          <p>Sizes: {item.sizes}</p>
                          <p> {item.colors}</p>
                          <p>Material: {item.material}</p>

                          <div className="fashion-counter">
                            <button
                              className="addCart-button-f"
                              onClick={() => handleAddClick(item)}
                              disabled={isInCart(item.$id)}
                            >
                              {cartLoading[item.$id] ? (
                                <>
                                  <ClipLoader size={10} color="#FFF" />
                                  {"   "}
                                </>
                              ) : isInCart(item.$id) ? (
                                "In "
                              ) : (
                                "Add "
                              )}
                              <FaShoppingCart />
                            </button>
                            <button
                              className="counter-button"
                              onClick={() =>
                                handleQuantityChange(item.$id, -1, item.stock)
                              }
                            >
                              -
                            </button>
                            <span className="counter-number">
                              {quantities[item.$id] || 1}
                            </span>
                            <button
                              className="counter-button"
                              onClick={() =>
                                handleQuantityChange(item.$id, 1, item.stock)
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p>No items found</p>
            )
          ) : (
            <p>No items found for {brandName}</p>
          )}

          {modalImage && (
            <div className="fashion-modal" onClick={handleCloseModal}>
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              <img
                className="modal-content"
                src={modalImage}
                alt="Fashion Item"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Fashion;
