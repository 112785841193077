import React, { useEffect, useState } from "react";
import { UserProvider, useUser } from "./UserContext";
import Home from "./Home";
import Header from "./Header";
import CartModal from "./CartModal";
import "./App.css";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import MiniPlayer from "./MiniPlayer";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Services from "./Services";
import LearnMusic from "./LearnMusic";
import Releases from "./Releases";
import SeparateBeat from "./SeparateBeat";
import SellBeat from "./SellBeat";
import SellTutorials from "./SellTutorials";
import PrivacyPolicy from "./PrivacyPolicy";
import ContactUs from "./ContactUs";
import Refund from "./Refund";
import Terms from "./Terms";
import Checkout from "./Checkout";
import Navigation from "./Navigation";
import BeatMaker from "./BeatMaker";
import Redirect from "./Redirect";
import PaymentFail from "./PaymentFail";
import Purchased from "./Purchased";
import ProfileSettings from "./ProfileSettings";
import Aconv from "./Aconv";
import YourProducts from "./YourProducts";
import Athumb from "./Athumb";
import SellMerch from "./SellMerch";
import Fashion from "./Fashion";
import Chess from "./chess/Chess";
import Room from "./room/Room";
import PrivateRoom from "./room/PrivateRoom";
import Carousal from "./Carousal";
import SoldProducts from "./SoldProducts";
import Offers from "./Offers";
import SeparateLearnMusic from "./SeparateLearnMusic";
import Notify from "./Notify";

function AppWrapper() {
  const [isHeaderScrolled, setHeaderScrolled] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);
  const { currentTrack, isPlaying, togglePlay } = useUser();
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const location = useLocation(); // This hook gives you access to the location object

  const toggleCartModal = () => setCartModalOpen(!isCartModalOpen);
  const toggleLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(!isLoginModalOpen);
  };
  const toggleRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(!isRegisterModalOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isBeatMakerPage = location.pathname === "/beatmaker";

      if (window.scrollY > 50 && isBeatMakerPage) {
        setHeaderScrolled(true); // Set the state when scrolled down
      } else {
        setHeaderScrolled(false); // Reset the state when at the top
      }
      if (showNavigation) {
        setShowNavigation(false); // Close navigation on scroll
      }
    };

    const handleOutsideClick = (event) => {
      if (!event.target.closest(".menu-icon1") && showNavigation) {
        setShowNavigation(false); // Close navigation on mouseup outside
      }
    };

    document.addEventListener("mouseup", handleOutsideClick);
    document.addEventListener("scroll", handleScroll, true);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, [showNavigation, location]);

  const toggleNavigation = (event) => {
    if (event.target.closest(".menu-icon1")) {
      setShowNavigation((prevState) => !prevState);
    }
  };

  return (
    <div className={`App ${isHeaderScrolled ? "scrolled" : ""}`}>
      <div className="header-above">
        <Header
          onOpenCart={toggleCartModal}
          onOpenLogin={toggleLoginModal}
          toggleNavigation={toggleNavigation}
        />
        <Navigation
          show={showNavigation}
          onClose={() => setShowNavigation(false)}
        />
      </div>

      <Routes>
        <Route
          path="/beats"
          element={
            <Home
              isCartOpen={isCartModalOpen}
              isLoginOpen={isLoginModalOpen}
              onCartClose={toggleCartModal}
              onLoginClose={toggleLoginModal}
            />
          }
        />
        <Route path="/beats/:beatName" element={<SeparateBeat />} />
        <Route path="/services" element={<Services />} />
        <Route path="/beatmaker" element={<BeatMaker />} />
        <Route path="/notify" element={<Notify />} />
        <Route path="/" element={<LearnMusic />} />
        <Route
          path="/learnmusic/:tutorialTitle"
          element={<SeparateLearnMusic />}
        />
        <Route path="/releases" element={<Releases />} />
        <Route path="/sell-beat" element={<SellBeat />} />
        <Route path="/sell-tutorials" element={<SellTutorials />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/paymentfail" element={<PaymentFail />} />
        <Route path="/purchased" element={<Purchased />} />
        <Route path="/aconv" element={<Aconv />} />
        <Route path="/chess" element={<Chess />} />
        <Route path="/room" element={<Room />} />
        <Route path="/athumb" element={<Athumb />} />
        <Route path="/profile-settings" element={<ProfileSettings />} />
        <Route path="/your-products" element={<YourProducts />} />{" "}
        <Route path="/your-products/sold" element={<SoldProducts />} />
        <Route path="/sell-merch" element={<SellMerch />} />
        <Route path="/fashion" element={<Fashion />} />
        <Route path="/PrivateRoom" element={<PrivateRoom />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/fashion/:brandName" element={<Fashion />} />
      </Routes>

      <MiniPlayer
        currentTrack={currentTrack}
        onTogglePlay={togglePlay}
        playing={isPlaying}
      />

      <CartModal isCartOpen={isCartModalOpen} onCartClose={toggleCartModal} />
      <LoginModal
        isLoginOpen={isLoginModalOpen}
        onLoginClose={toggleLoginModal}
        toggleRegisterModal={toggleRegisterModal}
      />
      <RegisterModal
        isRegisterOpen={isRegisterModalOpen}
        onRegisterClose={toggleRegisterModal}
        toggleLoginModal={toggleLoginModal}
      />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <AppWrapper />
        <Footer />
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
