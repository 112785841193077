import React, { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import client from "./appwriteConfig";
import { Databases } from "appwrite";
import "./css/Offers.css";
const databases = new Databases(client);

function Offers() {
  const { user } = useUser();
  const [coupons, setCoupons] = useState([]);
  const fetchSellerCoupons = async () => {
    if (!user) return;

    try {
      const couponsResponse = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_COUPONS_ID
      );
      setCoupons(couponsResponse.documents);
    } catch (error) {
      console.error("Failed to fetch seller coupons", error);
    }
  };
  useEffect(() => {
    fetchSellerCoupons();
  }, [user]);
  return (
    <div className="offers-table-container">
      <h2>Offers</h2>
      <table className="offers-table">
        <thead>
          <tr className="offer-header-row">
            <th>Coupon Code</th>
            <th>Product Type</th>
            <th>Discount Percent</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon) => (
            <tr key={coupon.$id} className="offer-row">
              <td>{coupon.couponCode}</td>
              <td>{coupon.category}</td>
              <td>{coupon.discountPercent}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Offers;
