import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Square from "./Square";
import GameOverModal from "./GameOverModal";
import PromotionModal from "./PromotionModal";
import { saveGameDetailsToAppwrite, saveMoveToAppwrite } from "./moveAppwrite";
import { handleSquareClick } from "./handleSquareClick"; // Import the new handleSquareClick function
import {
  getAllPossibleMoves,
  getChessNotation,
  makeMove,
} from "./chessFunctions";
import { minimax } from "./chessAI"; // Import AI logic from chessAI.js
import "./css/Board.css";
import { useUser } from "../UserContext";

// Import sound files
import pieceMoveSound from "./sounds/piece_move.wav";
import checkmateSound from "./sounds/checkmate.wav";

const initialBoardSetup = [
  ["r", "n", "b", "q", "k", "b", "n", "r"],
  ["p", "p", "p", "p", "p", "p", "p", "p"],
  ["", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", ""],
  ["P", "P", "P", "P", "P", "P", "P", "P"],
  ["R", "N", "B", "Q", "K", "B", "N", "R"],
];

const Board = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [board, setBoard] = useState(initialBoardSetup);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [legalMoves, setLegalMoves] = useState([]);
  const [isWhiteTurn, setIsWhiteTurn] = useState(true);
  const [inCheck, setInCheck] = useState({ white: false, black: false });
  const [checkmate, setCheckmate] = useState(false);
  const [winner, setWinner] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [moves, setMoves] = useState([]);
  const [castlingRights, setCastlingRights] = useState({
    whiteKingSide: true,
    whiteQueenSide: true,
    blackKingSide: true,
    blackQueenSide: true,
  });
  const [promotion, setPromotion] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("white");
  const [showGameOverModal, setShowGameOverModal] = useState(false);

  const { user } = useUser();
  const [volume, setVolume] = useState(0.5);
  const [gameMode, setGameMode] = useState("AI");

  useEffect(() => {
    if (gameStarted && gameMode === "AI") {
      if (
        (selectedColor === "black" && isWhiteTurn) ||
        (selectedColor === "white" && !isWhiteTurn)
      ) {
        setTimeout(handleAIMove, 500);
      }
    }
  }, [isWhiteTurn, gameStarted, gameMode]);

  useEffect(() => {
    if (user && user.email) {
      setUserEmail(user.email);
    } else {
      setUserEmail(null); // Set to null if no user is logged in
    }
  }, [user]);
  const handleSquareClickModified = (params) => {
    // This will allow the user to move pieces for both sides in manual mode
    if (gameMode === "Manual") {
      handleSquareClick(params);
      setIsWhiteTurn(!isWhiteTurn); // Toggle turn manually
    } else {
      // In AI mode, ensure only the current side can move
      if (
        (isWhiteTurn &&
          params.piece &&
          params.piece === params.piece.toUpperCase()) ||
        (!isWhiteTurn &&
          params.piece &&
          params.piece === params.piece.toLowerCase())
      ) {
        handleSquareClick(params);
      }
    }
  };

  const handleAIMove = () => {
    const aiColor = selectedColor === "white" ? "black" : "white";
    let depth = 3; // Reduced depth for faster moves

    let bestMove = null;
    let bestMoveValue = -Infinity;

    const possibleMoves = getAllPossibleMoves(aiColor, board);
    for (const move of possibleMoves) {
      const newBoard = makeMove(board, move);
      const moveValue = minimax(
        newBoard,
        depth - 1,
        false,
        -Infinity,
        Infinity
      );

      if (moveValue > bestMoveValue) {
        bestMoveValue = moveValue;
        bestMove = move;
      }
    }

    if (bestMove) {
      const [startRow, startCol] = bestMove.start;
      const [endRow, endCol] = bestMove.end;
      const newBoard = board.map((r) => [...r]);
      newBoard[endRow][endCol] = board[startRow][startCol];
      newBoard[startRow][startCol] = "";

      // Update board state
      setBoard(newBoard);
      setIsWhiteTurn(!isWhiteTurn); // AI finishes its turn, switch back to player's turn

      const audio = new Audio(pieceMoveSound);
      audio.volume = volume;
      audio.play();
    }
  };

  const handleStartGame = () => {
    startNewGame();
    setIsWhiteTurn(selectedColor === "white");

    if (gameMode === "AI" && selectedColor === "black") {
      setTimeout(handleAIMove, 500);
    }
  };
  const toggleGameMode = () => {
    setGameMode(gameMode === "AI" ? "Manual" : "AI");
  };
  const startNewGame = async () => {
    const gameDetails = {
      gameId: `game${Date.now()}`,
      player1Id: userEmail || "Anonymous", // If no user, set as "Anonymous"
      player2Id: "PlayerAI",
      player1Role: selectedColor === "white" ? "White" : "Black",
      player2Role: selectedColor === "white" ? "Black" : "White",
      currentTurn: "white",
      boardState: JSON.stringify(initialBoardSetup),
      moves: JSON.stringify([]),
    };

    const gameID = await saveGameDetailsToAppwrite(gameDetails);
    setGameId(gameID);
    setGameStarted(true);
  };

  const resetGame = () => {
    setBoard(initialBoardSetup);
    setSelectedPiece(null);
    setLegalMoves([]);
    setIsWhiteTurn(true);
    setInCheck({ white: false, black: false });
    setCheckmate(false);
    setWinner(null);
    setGameStarted(false);
    setPromotion(null);
    setCastlingRights({
      whiteKingSide: true,
      whiteQueenSide: true,
      blackKingSide: true,
      blackQueenSide: true,
    });
  };

  const handleGameOverModalClose = () => {
    setShowGameOverModal(false);
    resetGame();
  };

  const handlePromotion = (newPiece) => {
    const { row, col, color } = promotion;
    if (typeof row !== "undefined" && typeof col !== "undefined") {
      const promotedPiece =
        color === "white" ? newPiece.toUpperCase() : newPiece.toLowerCase();
      const newBoard = board.map((r) => [...r]);

      // Replace the pawn with the selected promotion piece
      newBoard[row][col] = promotedPiece;

      setBoard(newBoard); // Update the board state
      setPromotion(null); // Close the promotion modal
      setIsWhiteTurn(!isWhiteTurn); // Switch turns after promotion
      setSelectedPiece(null);
      setLegalMoves([]);

      // Trigger AI move if applicable
      if (color === "white") {
        setTimeout(() => handleAIMove(), 500); // AI moves after promotion for white
      }
    }
  };

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
  };

  return (
    <div className="board-container">
      {!gameStarted && (
        <div className="start-game">
          <button onClick={toggleGameMode}>
            {gameMode === "AI" ? "Switch to Manual Play" : "Switch to AI Play"}
          </button>
          <button className="start-button" onClick={handleStartGame}>
            Start New Game vs Computer
          </button>
        </div>
      )}
      {gameStarted && (
        <>
          <div className="player-info">
            <p>Player 2: PlayerAI (Black)</p>
          </div>
          <div
            className={`board ${
              selectedColor === "black" ? "invert-board" : ""
            }`}
          >
            {board.map((row, rowIndex) =>
              row.map((piece, colIndex) => (
                <Square
                  key={`${rowIndex}-${colIndex}`}
                  row={selectedColor === "white" ? rowIndex : 7 - rowIndex}
                  col={selectedColor === "white" ? colIndex : 7 - colIndex}
                  piece={piece}
                  isHighlighted={legalMoves.some(
                    (move) => move[0] === rowIndex && move[1] === colIndex
                  )}
                  inCheck={
                    (inCheck.white && piece === "K") ||
                    (inCheck.black && piece === "k")
                  }
                  onClick={() =>
                    handleSquareClickModified({
                      row: rowIndex,
                      col: colIndex,
                      board,
                      selectedPiece,
                      setSelectedPiece,
                      legalMoves,
                      setLegalMoves,
                      setBoard,
                      isWhiteTurn,
                      setIsWhiteTurn,
                      inCheck,
                      piece,
                      setInCheck,
                      checkmate,
                      setCheckmate,
                      setShowGameOverModal,
                      setWinner,
                      castlingRights,
                      setCastlingRights,
                      setPromotion,
                      promotion,
                      moves,
                      setMoves,
                      gameId,
                      selectedColor,
                      gameStarted, // Ensure gameStarted is passed here
                      volume,
                      saveMoveToAppwrite, // Ensure saving moves is passed in correctly
                    })
                  }
                />
              ))
            )}
          </div>
        </>
      )}
      <div className="player-info">
        <p>Player 1: {userEmail || "Anonymous"} (White)</p>
      </div>
      <div className="chess-volume-slider">
        <label>Volume:</label>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
      </div>
      {gameStarted && (
        <button className="restart-button" onClick={resetGame}>
          Restart Game
        </button>
      )}
      {showGameOverModal &&
        ReactDOM.createPortal(
          <GameOverModal winner={winner} onClose={handleGameOverModalClose} />,
          document.body
        )}
      {promotion &&
        ReactDOM.createPortal(
          <PromotionModal
            onPromote={handlePromotion}
            color={promotion.color}
          />,
          document.body
        )}
      <div className="moves-list">
        {moves.map((move, index) => (
          <div key={index}>{move}</div>
        ))}
      </div>
    </div>
  );
};

export default Board;
