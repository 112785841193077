import React, { useRef, useState } from "react";
import * as lamejs from "@breezystack/lamejs";
import {
  getStorage,
  ref as firebaseRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import client from "./appwriteConfig";
import { Client, Databases, Storage, ID } from "appwrite";
import { app } from "./firebaseConfig"; // Ensure you have this file with your Firebase config
import { useUser } from "./UserContext";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function SellBeat() {
  const { user } = useUser();
  const [mp3File, setMp3File] = useState(null);
  const [beatName, setBeatName] = useState("");
  const [originalMp3Url, setOriginalMp3Url] = useState("");
  const [convertedMp3Url, setConvertedMp3Url] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [artistName, setArtistName] = useState("");
  const [genre, setGenre] = useState("");
  const [tempo, setTempo] = useState("");
  const [cost, setCost] = useState("");
  const storage = getStorage(app);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  const handleAudioChange = (event) => {
    setMp3File(event.target.files[0]);
  };

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const uploadFile = async (fileBlob, filePath) => {
    const fileRef = firebaseRef(storage, filePath);
    await uploadBytes(fileRef, fileBlob);
    return getDownloadURL(fileRef);
  };
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const UploadSellBeatData = async () => {
    if (!mp3File || !imageFile) return;
    setLoading(true);
    setError(null);
    try {
      const capitalizedBeatName = capitalizeFirstLetterOfEachWord(beatName);
      const capitalizedArtistName = capitalizeFirstLetterOfEachWord(artistName);
      const audioFileType = mp3File.name.split(".").pop();
      const audioFileName = `${capitalizedBeatName}.${audioFileType}`;
      // Original MP3 upload and URL setting
      const originalAudioPath = `beats/${user.email}/${capitalizedBeatName}/${audioFileName}`;
      const originalUrl = await uploadFile(mp3File, originalAudioPath);
      setOriginalMp3Url(originalUrl);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        if (audioBuffer.numberOfChannels !== 2) {
          alert("The file must be a stereo audio file.");
          return;
        }

        const mp3Blob = await encodeAudioBufferToMp3(audioBuffer, 34); // Convert at 34 kbps
        const convertedAudioPath = `beats/${user.email}/${capitalizedBeatName}/low_${audioFileName}`;
        const convertedUrl = await uploadFile(mp3Blob, convertedAudioPath);
        setConvertedMp3Url(convertedUrl);

        const imageFileType = imageFile.name.split(".").pop();
        const imageFileName = `${capitalizedBeatName}.${imageFileType}`;
        const imagePath = `beats/${user.email}/${capitalizedBeatName}/albumArt/${imageFileName}`;
        const uploadedImageUrl = await uploadFile(imageFile, imagePath);
        setImageUrl(uploadedImageUrl);

        const productData = {
          artistName: capitalizedArtistName,
          beatName: capitalizedBeatName,
          genre,
          tempo: parseInt(tempo, 10),
          cost: parseInt(cost, 10),
          audioUrl: originalUrl,
          lowAudioUrl: convertedUrl,
          email: user.email,
          imageUrl: uploadedImageUrl,
          audioPath: originalAudioPath,
          lowAudioPath: convertedAudioPath,
          imagePath,
        };

        const database = new Databases(client);
        await database.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID,
          ID.unique(),
          productData
        );
        setLoading(false);
        navigate("/newbeats");
      };
      reader.readAsArrayBuffer(mp3File);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const encodeAudioBufferToMp3 = async (audioBuffer, bitrate) => {
    const mp3encoder = new lamejs.Mp3Encoder(
      2,
      audioBuffer.sampleRate,
      bitrate
    );
    const buffers = [
      audioBuffer.getChannelData(0),
      audioBuffer.getChannelData(1),
    ];
    let mp3Data = [];
    const sampleBlockSize = 1152;

    for (let i = 0; i < buffers[0].length; i += sampleBlockSize) {
      const leftChunk = convertFloat32ToInt16(
        buffers[0].subarray(i, i + sampleBlockSize)
      );
      const rightChunk = convertFloat32ToInt16(
        buffers[1].subarray(i, i + sampleBlockSize)
      );
      const mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);
      if (mp3buf.length > 0) {
        mp3Data.push(new Int8Array(mp3buf));
      }
    }

    const flush = mp3encoder.flush();
    if (flush.length > 0) {
      mp3Data.push(new Int8Array(flush));
    }

    return new Blob(mp3Data, { type: "audio/mp3" });
  };

  const convertFloat32ToInt16 = (buffer) => {
    let int16Buffer = new Int16Array(buffer.length);
    for (let i = 0; i < buffer.length; i++) {
      int16Buffer[i] = buffer[i] * 32767.5; // Scaling to int16 range
    }
    return int16Buffer;
  };

  return (
    <div className="sell-beats-container">
      <h2>Sell Your Beat</h2>
      <div className="form-group">
        <label>Artist Name:</label>
        <input
          type="text"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Beat Name:</label>
        <input
          type="text"
          value={beatName}
          onChange={(e) => setBeatName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Genre:</label>
        <input
          type="text"
          value={genre}
          onChange={(e) => setGenre(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Tempo:</label>
        <input
          type="number"
          value={tempo}
          onChange={(e) => setTempo(parseInt(e.target.value, 10) || 0)}
          required
          min="0"
        />
      </div>
      <div className="form-group">
        <label>Cost:</label>
        <input
          type="number"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Upload Audio File:</label>
        <input
          type="file"
          accept="audio/mpeg"
          onChange={handleAudioChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Upload Image File:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          required
        />
      </div>

      <button className="onSubmitButton" onClick={UploadSellBeatData}>
        {" "}
        {loading ? (
          <>
            Uploading...
            <ClipLoader color="#ffffff" loading={loading} size={20} />{" "}
          </>
        ) : (
          "Submit"
        )}
      </button>
      {error && <div className="error-message">{error}</div>}

      {/* {originalMp3Url && (
        <div>
          <h2>Original MP3:</h2>
          <audio controls src={originalMp3Url}>
            Your browser does not support the audio tag.
          </audio>
          <a href={originalMp3Url} download="original_audio.mp3">
            Download Original MP3
          </a>
        </div>
      )}
      {convertedMp3Url && (
        <div>
          <h2>Converted MP3:</h2>
          <audio controls src={convertedMp3Url}>
            Your browser does not support the audio tag.
          </audio>
          <a href={convertedMp3Url} download="converted_audio.mp3">
            Download Converted MP3
          </a>
        </div>
      )}{" "}
      {imageUrl && (
        <div>
          <h2>Uploaded Image:</h2>
          <img src={imageUrl} alt="Uploaded" style={{ width: "300px" }} />
        </div>
      )} */}
    </div>
  );
}

export default SellBeat;
