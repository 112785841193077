import { Storage } from "appwrite";
import client from "./appwriteConfig";

class InstrumentAudioContext {
  constructor() {
    this.audioContext = null;
    this.kitVolumes = {};
    this.gainNodes = {};
    this.isPlaying = {};
    this.muteStates = {};
    this.sources = {};
    this.intervals = {};
    this.buffers = new Map(); // Store loaded audio buffers
    this.metronome = {};
    this.currentMetronomeBeat = 0;
    this.currentAudioElement = null;
    this.volumes = {};
    this.volumeChangeListeners = {};
    this.bpm = 120; // Initialize BPM
    this.overallGainNode = null; // Overall gain node
    this.songGainNode = null; // Song-specific gain node
    this.songVolume = 0.5; // Initialize song volume

    const resumeAudioContext = () => {
      if (this.audioContext && this.audioContext.state === "suspended") {
        this.audioContext.resume();
      }
    };

    document.addEventListener("click", resumeAudioContext);
    document.addEventListener("keydown", resumeAudioContext);
  }

  getAudioContext() {
    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      this.overallGainNode = this.audioContext.createGain();
      this.overallGainNode.connect(this.audioContext.destination);
      this.songGainNode = this.audioContext.createGain();
      this.songGainNode.connect(this.overallGainNode);
    }
    return this.audioContext;
  }

  setBpm(bpm) {
    this.bpm = Math.max(20, Math.min(bpm, 400));
  }

  setSongVolume(volume) {
    if (this.songGainNode) {
      this.songGainNode.gain.setValueAtTime(
        volume,
        this.getAudioContext().currentTime
      );
      this.songVolume = volume;
      this.updateInstrumentVolumes();
    }
  }

  updateInstrumentVolumes() {
    for (const instrument in this.volumes) {
      const volume = this.volumes[instrument];
      if (this.gainNodes[instrument]) {
        const gainNode = this.gainNodes[instrument].gain;
        const effectiveVolume = volume * this.songVolume;
        gainNode.setValueAtTime(
          effectiveVolume,
          this.getAudioContext().currentTime
        );
      }
    }
  }

  getBpm() {
    return this.bpm;
  }

  setMute(mute, id) {
    this.muteStates[id] = mute;
    if (this.gainNodes[id]) {
      const gainNode = this.gainNodes[id].gain;
      const currentVolume = this.volumes[id] ?? 0.5;
      gainNode.setValueAtTime(
        mute ? 0 : currentVolume,
        this.audioContext.currentTime
      );
    }
  }

  isMuted(id) {
    return this.muteStates[id] ?? false;
  }

  updatePlaybackIntervals() {
    if (this.isPlaying) {
      clearInterval(this.intervals.sequencer);
      this.intervals.sequencer = setInterval(() => {
        // Your existing code for handling the playback
      }, 60000 / this.bpm / 4);
    }
  }

  async preloadAudio(kitId) {
    if (this.buffers.has(kitId)) {
      return this.buffers.get(kitId);
    }
    try {
      const storage = new Storage(client);
      const fileView = await storage.getFileView(
        process.env.REACT_APP_BUCKET_KIT_ID,
        kitId
      );
      const response = await fetch(fileView.href);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await this.getAudioContext().decodeAudioData(
        arrayBuffer
      );
      this.buffers.set(kitId, audioBuffer);
      return audioBuffer;
    } catch (error) {
      console.error("Error preloading sound:", error);
    }
  }

  playSound(kitId) {
    const buffer = this.buffers.get(kitId);
    if (buffer) {
      const context = this.getAudioContext();
      const source = context.createBufferSource();
      source.buffer = buffer;
      const gainNode = context.createGain();
      const volume = this.isMuted(kitId)
        ? 0
        : (this.kitVolumes[kitId] ?? 0.5) * this.songVolume; // Scale by song volume

      if (!isFinite(volume)) {
        console.error("Invalid volume value:", volume);
        return;
      }

      gainNode.gain.value = volume;
      source.connect(gainNode);
      gainNode.connect(context.destination);
      source.start(0);
    }
  }

  async loadAudio(instrument) {
    const response = await fetch(
      `/instruments/${instrument.toLowerCase()}.wav`
    );
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.getAudioContext().decodeAudioData(
      arrayBuffer
    );
    this.buffers.set(instrument, audioBuffer);

    // Create a gain node for this instrument if it doesn't already exist
    if (!this.gainNodes[instrument]) {
      const gainNode = this.getAudioContext().createGain();
      gainNode.connect(this.songGainNode); // Connect to the song gain node
      this.gainNodes[instrument] = gainNode;
    }
  }

  playNoteOnMouseDown(noteIndex, notes, instrument) {
    if (!this.getAudioContext() || !this.buffers.get(instrument)) return;

    const source = this.getAudioContext().createBufferSource();
    source.buffer = this.buffers.get(instrument);
    const baseFrequency = 261.63; // Frequency of middle C (C4)
    const noteFrequency =
      baseFrequency * Math.pow(2, (noteIndex - notes.length + 48) / 12); // Adjusted for correct pitch
    source.playbackRate.value = noteFrequency / baseFrequency;
    source.connect(this.gainNodes[instrument]); // Connect to the instrument's gain node
    source.start(0);
    this.sources[noteIndex] = source;

    source.onended = () => {
      delete this.sources[noteIndex];
    };
  }

  stopNoteWithDelay(noteIndex) {
    if (this.sources[noteIndex]) {
      const source = this.sources[noteIndex];
      setTimeout(() => {
        source.stop();
        delete this.sources[noteIndex];
      }, 400); // Delay of 100ms
    }
  }

  isNotePlaying(noteIndex) {
    return !!this.sources[noteIndex];
  }

  playPianoRollNote(noteIndex, notes, instrument) {
    if (!this.getAudioContext() || !this.buffers.get(instrument)) return;

    const source = this.getAudioContext().createBufferSource();
    source.buffer = this.buffers.get(instrument);
    const baseFrequency = 261.63; // Frequency of middle C (C4)
    const noteFrequency =
      baseFrequency * Math.pow(2, (noteIndex - notes.length + 48) / 12);

    if (!isFinite(noteFrequency)) {
      console.error("Invalid note frequency:", noteFrequency);
      return;
    }

    source.playbackRate.value = noteFrequency / baseFrequency;

    const gainNode = this.getAudioContext().createGain();
    const volume = this.isMuted(instrument)
      ? 0
      : (this.volumes[instrument] ?? 0.5) * this.songVolume; // Scale by song volume

    if (!isFinite(volume)) {
      console.error("Invalid volume:", volume);
      return;
    }

    gainNode.gain.setValueAtTime(volume, this.getAudioContext().currentTime);

    source.connect(gainNode);
    gainNode.connect(this.songGainNode);

    source.start(0);
    this.sources[noteIndex] = source;

    // Stop the note after 200ms
    setTimeout(() => {
      source.stop();
      delete this.sources[noteIndex];
    }, 300);

    source.onended = () => {
      delete this.sources[noteIndex];
    };
  }

  stopPianoRollNote(noteIndex, notes, instrument) {
    if (this.sources[noteIndex]) {
      const source = this.sources[noteIndex];
      source.stop();
      delete this.sources[noteIndex];
    }
  }

  stopAllNotes() {
    Object.keys(this.sources).forEach((key) => {
      this.sources[key].stop();
      delete this.sources[key];
    });
  }

  notifyVolumeChange(id, volume) {
    if (this.volumeChangeListeners[id]) {
      this.volumeChangeListeners[id](volume);
    }
  }

  registerVolumeChangeListener(id, listener) {
    this.volumeChangeListeners[id] = listener;
  }

  setKitVolume(volume, kitId) {
    if (!this.gainNodes[kitId]) {
      this.getAudioContext();
      const gainNode = this.audioContext.createGain();
      gainNode.connect(this.audioContext.destination);
      this.gainNodes[kitId] = gainNode;
    }

    const currentTime = this.audioContext.currentTime;
    const gainNode = this.gainNodes[kitId].gain;
    gainNode.cancelScheduledValues(currentTime);
    gainNode.setValueAtTime(gainNode.value, currentTime);
    // Ensure volume is greater than 0 to avoid RangeError
    const safeVolume = Math.max(volume, 1.4013e-45);
    gainNode.exponentialRampToValueAtTime(safeVolume, currentTime + 0.1);

    this.kitVolumes[kitId] = volume;
    this.notifyVolumeChange(kitId, volume);
  }

  getInstVolume(instrument) {
    return this.volumes[instrument] ?? 0.5; // Default to 0.5 if undefined
  }

  setInstVolume(volume, instrument) {
    if (!this.gainNodes[instrument]) {
      this.getAudioContext();
      const gainNode = this.audioContext.createGain();
      gainNode.connect(this.songGainNode); // Connect to the song gain node
      this.gainNodes[instrument] = gainNode;
    }

    const currentTime = this.audioContext.currentTime;
    const gainNode = this.gainNodes[instrument].gain;
    gainNode.cancelScheduledValues(currentTime);
    gainNode.setValueAtTime(gainNode.value, currentTime);
    // Ensure volume is greater than 0 to avoid RangeError
    const safeVolume = Math.max(volume, 1.4013e-45);
    gainNode.exponentialRampToValueAtTime(safeVolume, currentTime + 0.1); // Smoother transition

    // Store the new volume value
    this.volumes[instrument] = volume;

    // Notify listeners about the volume change
    this.notifyVolumeChange(instrument, volume);

    // Update the gain node to account for the global song volume
    const effectiveVolume = volume * this.songVolume;
    gainNode.setValueAtTime(effectiveVolume, currentTime);
  }

  toggleMetronome() {
    if (this.metronome.isOn) {
      this.stopMetronome();
    } else {
      this.startMetronome();
    }
  }

  playMetronomeSound() {
    if (!this.getAudioContext()) return;

    const osc = this.getAudioContext().createOscillator();
    const gain = this.getAudioContext().createGain();

    osc.type = "sine";
    if (this.currentMetronomeBeat === 1) {
      osc.frequency.setValueAtTime(600, this.getAudioContext().currentTime); // Lower frequency for the first beat
    } else {
      osc.frequency.setValueAtTime(5600, this.getAudioContext().currentTime); // Higher frequency for other beats
    }

    gain.gain.setValueAtTime(0.12, this.getAudioContext().currentTime); // Volume control
    gain.gain.exponentialRampToValueAtTime(
      0.001,
      this.getAudioContext().currentTime + 0.05
    );

    osc.connect(gain);
    gain.connect(this.getAudioContext().destination);

    osc.start(this.getAudioContext().currentTime);
    osc.stop(this.getAudioContext().currentTime + 0.05);
  }

  startMetronome() {
    this.metronome.isOn = true;
    const bpm = this.bpm;
    const interval = (60 / bpm) * 1000; // Interval in milliseconds

    this.metronome.intervalId = setInterval(() => {
      this.playMetronomeSound();
    }, interval);
  }

  stopMetronome() {
    this.metronome.isOn = false;
    clearInterval(this.metronome.intervalId);
    this.metronome.intervalId = null;
  }

  stopMetronomeSound() {
    if (this.metronome.oscillator) {
      this.metronome.oscillator.stop();
      this.metronome.oscillator.disconnect();
      this.metronome.oscillator = null;
    }
  }

  loadTrack(storage, bucketId, trackUrl, audioElement, playing) {
    const newSrc = storage.getFileView(bucketId, trackUrl);
    if (audioElement.src !== newSrc) {
      audioElement.src = newSrc;
      audioElement.load();
      audioElement.volume = 0.5;

      const onReadyToPlay = () => {
        if (playing) audioElement.play();
        audioElement.removeEventListener(
          "canplaythrough",
          onReadyToPlay,
          false
        );
      };

      audioElement.addEventListener("canplaythrough", onReadyToPlay, false);
    }
  }

  playAudio(audioElement) {
    if (audioElement && audioElement.readyState >= 3) {
      audioElement.play();
    }
  }

  pauseAudio(audioElement) {
    if (audioElement) {
      audioElement.pause();
    }
  }

  seekAudio(audioElement, time) {
    if (audioElement) {
      audioElement.currentTime = time;
    }
  }

  stopNotesForInstrument(instrumentName) {
    if (this.sources[instrumentName]) {
      this.sources[instrumentName].forEach((source) => source.stop());
      delete this.sources[instrumentName];
    }
  }

  setAudioVolume(audioElement, volume) {
    if (audioElement) {
      audioElement.volume = volume;
    }
  }
}

export default new InstrumentAudioContext();
