// Header.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import "./css/Header.css"; // Import the CSS file
import { FaBars, FaShoppingCart } from "react-icons/fa";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";

function Header({ onOpenCart, onOpenLogin, toggleNavigation }) {
  const { user, logout, cart } = useUser();
  const [showNavigation, setShowNavigation] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const avatarRef = useRef(null);
  const navigate = useNavigate();

  const closeNavigation = () => {
    setShowNavigation(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    logout();
    setShowDropdown(false);
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      avatarRef.current &&
      !avatarRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header">
      <div className="header-main">
        <div className="header-2">
          <div className="logo">
            <Link to="/" className="logo-header">
              <img src="./assets/hxabyte-up.png" />
              <img src="./assets/hxabyte-down.png" />
            </Link>
          </div>
          <div className="user-cart-section">
            <div
              className="cart-icon"
              onClick={onOpenCart}
              style={{ marginLeft: "20px" }}
            >
              <FaShoppingCart />
              <p>{cart.length}</p>{" "}
            </div>
            <div className="user-info">
              {user ? (
                <>
                  <div
                    className="user-avatar"
                    onClick={toggleDropdown}
                    ref={avatarRef}
                  >
                    {getInitial(user.name)}
                  </div>
                  {showDropdown && (
                    <div className="dropdown-menu" ref={dropdownRef}>
                      <div className="dropdown-header">
                        Welcome, {user.name}
                      </div>
                      <button
                        onClick={() => {
                          navigate("/purchased");
                          setShowDropdown(false);
                        }}
                      >
                        Purchased
                      </button>
                      <button
                        onClick={() => {
                          navigate("/your-products");
                          setShowDropdown(false);
                        }}
                      >
                        Your Products
                      </button>
                      <button
                        onClick={() => {
                          navigate("/offers");
                          setShowDropdown(false);
                        }}
                      >
                        Offers
                      </button>
                      <button
                        onClick={() => {
                          navigate("/sell-beat");
                          setShowDropdown(false); // If you want to hide the dropdown after navigation
                        }}
                      >
                        <div>Sell Beats</div>
                      </button>
                      <button
                        onClick={() => {
                          navigate("/sell-tutorials");
                          setShowDropdown(false); // If you want to hide the dropdown after navigation
                        }}
                      >
                        <div>Sell Tutorials</div>
                      </button>
                      <button
                        onClick={() => {
                          navigate("/sell-merch");
                          setShowDropdown(false); // If you want to hide the dropdown after navigation
                        }}
                      >
                        <div>Sell Merchandise </div>
                      </button>{" "}
                      <button
                        onClick={() => {
                          navigate("/profile-settings");
                          setShowDropdown(false);
                        }}
                      >
                        Profile Settings
                      </button>{" "}
                      <button onClick={handleLogout}>Logout</button>
                    </div>
                  )}
                </>
              ) : (
                <button onClick={onOpenLogin} className="button-logout">
                  Login / Register
                </button>
              )}
            </div>{" "}
            <FaBars className="menu-icon1" onClick={toggleNavigation} />
          </div>
        </div>
        <Navigation show={showNavigation} onClose={closeNavigation} />
      </div>
    </div>
  );
}

export default Header;
