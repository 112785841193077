import React, { useEffect, useState } from "react";
import { Databases, Storage } from "appwrite";
import client from "./appwriteConfig";
import "./css/LearnMusic.css"; // Import the CSS file
import { BeatLoader, ClipLoader } from "react-spinners"; // Import loaders
import { useUser } from "./UserContext";
import { FaPlay, FaPlayCircle, FaShoppingCart } from "react-icons/fa";
import Modal from "react-modal"; // Import Modal
import Carousal from "./Carousal";
import { BsInfoCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

Modal.setAppElement("#root"); // Set the root element for accessibility

const extractFileName = (fileName) => {
  if (!fileName) {
    console.error("FileName is undefined");
    return ""; // Return an empty string or some default value if filename is undefined
  }
  const parts = fileName.split("_");
  return parts.slice(2).join("_");
};

function LearnMusic() {
  const { user, cart, addToCart, cartLoading, fetchCartData } = useUser();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [infoVisible, setInfoVisible] = useState(false); // State to toggle info visibility

  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, [user]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const database = new Databases(client);
      const response = await database.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_TUTORIALS_ID
      );

      const fetchedVideos = await Promise.all(
        response.documents.map(async (doc) => {
          return {
            ...doc,
            videosData: JSON.parse(doc.videos),
            thumbnailUrls: JSON.parse(doc.videos).map((video) => ({
              videoUrl: video.videoUrl,
              thumbnailUrl: video.thumbnailUrl,
              videoName: extractFileName(video.videoName),
            })),
            inCart: cart.some((item) => item.productId === doc.$id),
            description: doc.description,
          };
        })
      );

      setVideos(fetchedVideos);
      setLoading(false);
    };

    fetchData();
  }, [user, cart]); // Depend on cart to refresh inCart status

  const isInCart = (productId) =>
    cart.some((item) => item.productId === productId);

  const handlePlay = async (videoUrl, isFree, tutorial) => {
    if (isFree) {
      setPlayingVideo({ id: videoUrl, url: videoUrl });
    } else {
      setSelectedTutorial(tutorial);
      setModalIsOpen(true);
    }
  };
  const handleAddToCart = (tutorial) => {
    if (!isInCart(tutorial.$id)) {
      addToCart({
        ...tutorial,
        TUTORIALinfo: JSON.stringify({
          tutorialTitle: tutorial.tutorialTitle,
          videoUrl: tutorial.videosData[0].videoUrl,
          authorName: tutorial.authorName,
          thumbnailUrl: tutorial.thumbnailUrls[0].thumbnailUrl,
          cost: tutorial.cost,
          email: user.email,
        }),
        productType: "tutorial",
      });
    }
  };

  const renderVideos = () => {
    if (loading) {
      return (
        <div className="centered-loader">
          <BeatLoader color="#3498db" />
        </div>
      );
    }
    const toggleInfo = () => setInfoVisible(!infoVisible); // Toggle the info visibility

    return videos.map((video, index) => (
      <div key={index}>
        <div className="tutorial-card">
          <div className="tutorial-title">
            <Link
              to={`/learnmusic/${video.tutorialTitle
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
            >
              {video.tutorialTitle}
            </Link>
          </div>
          <p>{video.description}</p>
          <div className="video-card">
            <div className="video-carded">
              {video.thumbnailUrls.map((thumbnail, idx) => {
                // Remove .mp4 from videoName and add numbering
                const formattedVideoName = `${idx + 1}. ${thumbnail.videoName}`;
                const isFirstVideo = idx === 0; // Determine if it's the first video

                return (
                  <div key={idx} className="thumbnail-container">
                    {playingVideo && playingVideo.id === thumbnail.videoUrl ? (
                      <div className="video-whileplay">
                        <video
                          controls
                          autoPlay
                          className="video-playing"
                          controlsList="nodownload"
                        >
                          <source src={playingVideo.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <p className="video-name">{formattedVideoName}</p>
                      </div>
                    ) : (
                      <>
                        <img
                          src={thumbnail.thumbnailUrl}
                          alt="Thumbnail"
                          className={`thumbnail-lrnmus ${
                            !isFirstVideo ? "not-first-video" : ""
                          }`}
                        />
                        <button
                          className="lrnmus-play-button"
                          onClick={() =>
                            handlePlay(thumbnail.videoUrl, isFirstVideo, video)
                          }
                        >
                          <FaPlay />
                        </button>
                        <p className="video-name">{formattedVideoName}</p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="video-details">
              <div className="details-row">
                <div className="detail-tut">
                  <p>Author:</p> {video.authorName}
                </div>
                <div className="detail-tut">
                  <p>Category:</p> {video.category}
                </div>
                <div className="detail-tut">
                  <p> Duration:</p> {video.duration}
                </div>
                <div className="detail-tut">
                  <p>No. of videos:</p> {video.videosData.length}
                </div>
              </div>
              <div className="cart-button-tut">
                <button
                  className="add-to-cart-button in-cart"
                  onClick={() => handleAddToCart(video)}
                  disabled={isInCart(video.$id)}
                >
                  <p className="video-cost">₹ {video.cost}</p>
                  {cartLoading[video.$id] ? (
                    <ClipLoader size={10} color="#FFF" /> // Spinner shows while loading
                  ) : isInCart(video.$id) ? (
                    "In Cart"
                  ) : (
                    "ADD"
                  )}
                  <FaShoppingCart />
                </button>
                <button onClick={toggleInfo} className="info-btn-lm">
                  <BsInfoCircle /> {/* Display the information icon */}
                </button>
                {infoVisible && (
                  <div className="info-message-lm">
                    The first video is free as a preview—unlock full access to
                    all course videos by purchasing now!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="learn-music-container">
      <Carousal />
      <h2 className="learn-music-header">Learn Music 🖥️</h2>
      <div className="video-list">{renderVideos()}</div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Buy Now Modal"
        className="buy-now-modal"
        overlayClassName="buy-now-overlay"
      >
        <div className="buy-now-header">
          <h2>Buy Now to Access Full Course</h2>{" "}
          <span
            className="buy-modal-close"
            onClick={() => setModalIsOpen(false)}
          >
            &times;
          </span>
        </div>
        {selectedTutorial && (
          <div>
            {" "}
            <h3>{selectedTutorial.tutorialTitle}</h3>
            <p>Author: {selectedTutorial.authorName}</p>
            <p>Category: {selectedTutorial.category}</p>
            <p>Duration: {selectedTutorial.duration}</p>
            <p>₹ {selectedTutorial.cost}</p>
            <button
              className="add-cart-buynow-modal in-cart"
              onClick={() => {
                handleAddToCart(selectedTutorial);
                setModalIsOpen(false);
              }}
              disabled={isInCart(selectedTutorial.$id)}
            >
              {cartLoading[selectedTutorial.$id] ? (
                <ClipLoader size={10} color="#FFF" />
              ) : isInCart(selectedTutorial.$id) ? (
                "In Cart"
              ) : (
                "ADD"
              )}
              <FaShoppingCart />
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default LearnMusic;
