import React, { useEffect, useState } from "react";
import { Databases, Query, Storage, ID } from "appwrite";
import client from "./appwriteConfig";
import { useUser } from "./UserContext";
import { BeatLoader, ClipLoader } from "react-spinners";
import "./css/YourProducts.css";
import { FaTrash } from "react-icons/fa";
import Select from "react-select";
import genres from "./genreBeat";
import { useNavigate } from "react-router-dom";
import * as lamejs from "@breezystack/lamejs";
import customStyles from "./css/StylesForSelect";
import { encodeAudioBufferToMp3 } from "./convertToMp3";
import {
  getStorage,
  ref as firebaseRef,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { app } from "./firebaseConfig";
import ConfirmModal from "./ConfirmModal";
import { BsPauseFill, BsPlayFill } from "react-icons/bs";
const databases = new Databases(client);
const storage = new Storage(client);

const YourProducts = () => {
  const { user } = useUser();
  const [beats, setBeats] = useState([]);
  const [playingBeat, setPlayingBeat] = useState(null);
  const [audio, setAudio] = useState(new Audio());
  const [tutorials, setTutorials] = useState([]);
  const [fashions, setFashions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(null);
  const [editing, setEditing] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [newImage, setNewImage] = useState(null); // State for new image file
  const [coupons, setCoupons] = useState([]);
  const [couponLoading, setCouponLoading] = useState(false);
  const [deletingCoupon, setDeletingCoupon] = useState(null);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentBeat, setCurrentBeat] = useState(null);
  const [couponInputs, setCouponInputs] = useState({
    beat: { code: "", discount: "" },
    tutorial: { code: "", discount: "" },
    fashion: { code: "", discount: "" },
  });
  const [selectedCoupons, setSelectedCoupons] = useState({});
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const genreOptions = genres.map((genre) => ({ value: genre, label: genre }));
  const styles = customStyles();
  useEffect(() => {
    return () => {
      audio.pause();
      // Release the audio resource
    };
  }, [audio]);
  useEffect(() => {
    const currentAudio = new Audio();
    setAudio(currentAudio);

    return () => {
      currentAudio.pause(); // Cleanup to pause the audio when component unmounts
    };
  }, []);
  const togglePlay = (beat) => {
    const isCurrentlyPlaying = playingBeat && playingBeat.$id === beat.$id;

    if (isCurrentlyPlaying && !audio.paused) {
      audio.pause();
      setPlayingBeat(null);
    } else {
      if (!isCurrentlyPlaying || audio.paused) {
        // console.log(beat.lowAudioUrl);
        audio.src = beat.lowAudioUrl; // Set the audio source to lowAudioUrl
        audio.play();
        setPlayingBeat(beat);
      }
    }
  };

  // Cleanup audio on component unmount

  const fetchSellerCoupons = async () => {
    if (!user) return;

    try {
      const couponsResponse = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_COUPONS_ID,
        [Query.equal("validSellerMail", user.email)]
      );
      setCoupons(couponsResponse.documents);
    } catch (error) {
      console.error("Failed to fetch seller coupons", error);
    }
  };

  useEffect(() => {
    fetchSellerCoupons();
  }, [user]);

  useEffect(() => {
    const fetchProductsAndTutorials = async () => {
      if (!user) return;

      try {
        const beatsResponse = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID,
          [Query.equal("email", user.email), Query.limit(1000)]
        );

        const tutorialsResponse = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
          [Query.equal("email", user.email), Query.limit(1000)]
        );

        const fashionsResponse = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_FASHION_ID,
          [Query.equal("email", user.email), Query.limit(1000)]
        );

        // Fetch image URLs from Appwrite Storage for each item
        const fetchImageUrls = async (items, field, bucketId) => {
          return await Promise.all(
            items.map(async (item) => {
              const fileView = await storage.getFileView(bucketId, item[field]);
              return { ...item, imageUrlView: fileView.href };
            })
          );
        };

        const beatsWithImages = await fetchImageUrls(
          beatsResponse.documents,
          "imageUrl",
          process.env.REACT_APP_BUCKET_BEATS_ID
        );
        const tutorialsWithImages = await fetchImageUrls(
          tutorialsResponse.documents,
          "thumbnailUrl",
          process.env.REACT_APP_BUCKET_TUTORIALS_ID
        );
        const fashionsWithImages = await fetchImageUrls(
          fashionsResponse.documents,
          "fashionUrl",
          process.env.REACT_APP_BUCKET_FASHION_ID
        );

        setBeats(beatsWithImages);
        setTutorials(tutorialsWithImages);
        setFashions(fashionsWithImages);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch products and tutorials", error);
        setLoading(false);
      }
    };

    fetchProductsAndTutorials();
  }, [user]);
  const navigate = useNavigate();
  const handleSoldClick = () => {
    navigate("/your-products/sold");
  };
  const openConfirmModal = (action) => {
    setConfirmAction(() => action); // Store the deletion function
    setConfirmModalOpen(true);
  };

  const confirmDeletion = () => {
    if (confirmAction) {
      confirmAction(); // Execute the stored deletion function
    }
    setConfirmModalOpen(false);
    setConfirmAction(null); // Clear the action after execution
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setConfirmAction(null);
  };

  // Example usage with the deletion function for a beat or fashion item
  const handleDeleteFashionClick = (
    collectionId,
    documentId,
    fileIds,
    bucketId
  ) => {
    openConfirmModal(() =>
      handleDeleteFashion(collectionId, documentId, fileIds, bucketId)
    );
  };

  // Example usage with the deletion function for a tutorial
  const handleDeleteClick = (productId) => {
    openConfirmModal(() => handleDeleteTutorial(productId));
    openConfirmModal(() => handleDeleteBeat(productId));
  };

  const handleDeleteFashion = async (
    collectionId,
    documentId,
    fileIds,
    bucketId
  ) => {
    setDeleting(documentId);
    console.log(collectionId, documentId, fileIds, bucketId);
    try {
      // First delete the files from storage
      for (const fileId of fileIds) {
        try {
          await storage.deleteFile(bucketId, fileId);
        } catch (error) {
          console.error(`Failed to delete file with ID: ${fileId}`, error);
        }
      }
      console.log("file deleted");
      // After successfully deleting files, delete the document
      await databases.deleteDocument(
        process.env.REACT_APP_DATABASE_ID,
        collectionId,
        documentId
      );
      console.log("doc deleted");

      // Update local state to reflect the deletion
      setBeats(beats.filter((beat) => beat.$id !== documentId));
      setFashions(fashions.filter((fashion) => fashion.$id !== documentId));
    } catch (error) {
      console.error("Failed to delete item", error);
    } finally {
      setDeleting(null);
    }
  };
  const handleDeleteBeat = async (beatId) => {
    setLoading(true);
    try {
      // Fetch the tutorial document to find the paths for videos and thumbnails
      const beatDoc = await databases.getDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_BEATS_ID,
        beatId
      );

      // Extract video and thumbnail paths stored in the document
      const audioPath = beatDoc.audioPath;
      const lowAudioPath = beatDoc.lowAudioPath;
      const imagePath = beatDoc.imagePath;
      const beatPaths = [audioPath, lowAudioPath, imagePath];
      const storage = getStorage(app);

      // Function to delete files from Firebase Storage
      const deleteFiles = async (filePaths) => {
        for (const filePath of filePaths) {
          const fileRef = firebaseRef(storage, filePath);
          deleteObject(fileRef).catch((error) => {
            // Check if the error is because the file does not exist
            if (error.code === "storage/object-not-found") {
              console.log(`File not found, skipping deletion: ${filePath}`);
            } else {
              // Log other errors and continue
              console.error(`Failed to delete file: ${filePath}`, error);
            }
          });
        }
      };

      // Delete all video files
      await deleteFiles(beatPaths);

      // Delete all thumbnail files

      // After deleting all files, delete the tutorial document
      await databases.deleteDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_BEATS_ID,
        beatId
      );

      // Update local state to reflect the deletion
      setBeats(beats.filter((beat) => beat.$id !== beatId));

      setLoading(false);
    } catch (error) {
      console.error("Failed to delete tutorial:", error);
      setLoading(false);
    }
  };
  const handleDeleteTutorial = async (tutorialId) => {
    setLoading(true);
    try {
      // Fetch the tutorial document to find the paths for videos and thumbnails
      const tutorialDoc = await databases.getDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
        tutorialId
      );

      // Extract video and thumbnail paths stored in the document
      const { videoPaths, thumbnailPaths } = JSON.parse(
        tutorialDoc.tutorialFilesPaths
      );

      const storage = getStorage(app);

      // Function to delete files from Firebase Storage
      const deleteFiles = async (filePaths) => {
        for (const filePath of filePaths) {
          const fileRef = firebaseRef(storage, filePath);
          await deleteObject(fileRef);
        }
      };

      // Delete all video files
      await deleteFiles(videoPaths);

      // Delete all thumbnail files
      await deleteFiles(thumbnailPaths);

      // After deleting all files, delete the tutorial document
      await databases.deleteDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
        tutorialId
      );

      // Update local state to reflect the deletion
      setTutorials(tutorials.filter((tutorial) => tutorial.$id !== tutorialId));

      setLoading(false);
    } catch (error) {
      console.error("Failed to delete tutorial:", error);
      setLoading(false);
    }
  };

  const handleEdit = (item, type) => {
    setEditing(item.$id);

    setEditValues({
      name:
        type === "beat"
          ? item.beatName
          : type === "tutorial"
          ? item.tutorialTitle
          : item.itemName,
      cost: item.cost,
      description: item.description || "",
      tempo: item.tempo,
      type,
      genres: item.genres
        ? item.genres
            .split(", ")
            .map((genre) => ({ value: genre.trim(), label: genre.trim() }))
        : [],
    });
    setNewImage(null); // Reset new image file when editing starts
    if (type === "beat" && item.genres) {
      const genreArray = item.genres.split(", ").map((genre) => genre.trim());
      const selectedGenreOptions = genreOptions.filter((option) =>
        genreArray.includes(option.value)
      );
      setSelectedGenres(selectedGenreOptions);
    } else {
      setSelectedGenres([]); // Clear genres if not editing a beat or no genres found
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "cost" && value && !/^\d+$/.test(value)) {
      return; // Only allow integer values for cost
    }
    setEditValues({ ...editValues, [name]: value });
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]); // Set the new image file
  };
  const handleGenreChange = (selectedOptions) => {
    setSelectedGenres(selectedOptions || []);
    setEditValues((prevValues) => ({
      ...prevValues,
      genres: selectedOptions || [],
    }));
  };
  const handleAudioUpload = async (audioFile, item) => {
    if (!audioFile || !user) return;

    const capitalizedBeatName = item.beatName.replace(/\b\w/g, (c) =>
      c.toUpperCase()
    );
    const capitalizedArtistName = (item.artistName || "Unknown").replace(
      /\b\w/g,
      (c) => c.toUpperCase()
    );

    const audioFileType = audioFile.name.split(".").pop();
    const originalAudioPath = `beats/${user.email}/${capitalizedBeatName}/${capitalizedArtistName}_${capitalizedBeatName}.${audioFileType}`;
    const convertedAudioPath = `beats/${user.email}/${capitalizedBeatName}/low_${capitalizedArtistName}_${capitalizedBeatName}.mp3`;

    const fbStorage = getStorage(app);

    try {
      const audioRef = firebaseRef(fbStorage, originalAudioPath);
      await uploadBytes(audioRef, audioFile);
      const originalUrl = await getDownloadURL(audioRef);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        if (audioBuffer.numberOfChannels !== 2) {
          alert("The file must be stereo.");
          return;
        }

        const processedBuffer = await encodeAudioBufferToMp3(audioBuffer, 64);
        const lowRef = firebaseRef(fbStorage, convertedAudioPath);
        await uploadBytes(lowRef, processedBuffer);
        const lowUrl = await getDownloadURL(lowRef);

        await databases.updateDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID,
          item.$id,
          {
            audioUrl: originalUrl,
            lowAudioUrl: lowUrl,
            audioPath: originalAudioPath,
            lowAudioPath: convertedAudioPath,
          }
        );

        // Optional: update local state
        setBeats((prevBeats) =>
          prevBeats.map((b) =>
            b.$id === item.$id
              ? { ...b, audioUrl: originalUrl, lowAudioUrl: lowUrl }
              : b
          )
        );

        console.log("Audio uploaded and updated successfully.");
      };

      reader.readAsArrayBuffer(audioFile);
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  const handleSave = async (item) => {
    const { type, name, cost, tempo, description } = editValues;
    let { genres } = editValues; // Convert the array of genres to a comma-separated string
    // If genres are selected and it's a beat, format genres as a string
    let genreString = "";
    if (type === "beat" && Array.isArray(genres) && genres.length > 0) {
      genreString = genres.map((genre) => genre.value).join(", ");
    }
    const updateData =
      type === "beat"
        ? {
            beatName: name,
            cost: parseInt(cost, 10),
            tempo: parseInt(tempo, 10),
          }
        : type === "tutorial"
        ? {
            tutorialTitle: name,
            cost: parseInt(cost, 10),
            description: description || "",
          }
        : { itemName: name, cost: parseInt(cost, 10) };
    if (type === "beat" && genreString) {
      updateData.genre = genreString;
    }
    const collectionId =
      type === "beat"
        ? process.env.REACT_APP_COLLECTION_BEATS_ID
        : type === "tutorial"
        ? process.env.REACT_APP_COLLECTION_TUTORIALS_ID
        : process.env.REACT_APP_COLLECTION_FASHION_ID;

    try {
      if (newImage) {
        const fashionBucketId = process.env.REACT_APP_BUCKET_FASHION_ID;

        if (type === "tutorial") {
          const tutorialData = tutorials.find((t) => t.$id === item.$id);
          console.log(tutorialData);
          if (!tutorialData.thumbnailPath) {
            console.error("No existing thumbnail path for this tutorial.");
            return;
          }

          const fbStorage = getStorage(app);
          const thumbnailRef = firebaseRef(
            fbStorage,
            tutorialData.thumbnailPath
          );

          // Upload the new image to the existing path
          await uploadBytes(thumbnailRef, newImage)
            .then(async () => {
              updateData.thumbnailUrl = await getDownloadURL(thumbnailRef);
            })
            .catch((error) => {
              console.error("Failed to upload new thumbnail:", error);
            });
        } else if (type === "beat") {
          const beatData = beats.find((t) => t.$id === item.$id);
          // console.log(beatData);
          if (!beatData.imagePath) {
            console.error("No existing thumbnail path for this tutorial.");
            return;
          }

          const fbStorage = getStorage(app);
          const imageRef = firebaseRef(fbStorage, beatData.imagePath);

          // Upload the new image to the existing path
          await uploadBytes(imageRef, newImage)
            .then(async () => {
              updateData.imageUrl = await getDownloadURL(imageRef);
            })
            .catch((error) => {
              console.error("Failed to upload new thumbnail:", error);
            });
        } else {
          // Handle beats and fashion
          const fileId = ID.unique();
          const uploadedFile = await storage.createFile(
            fashionBucketId,
            fileId,
            newImage
          );
          updateData[type === "beat" ? "imageUrl" : "fashionUrl"] =
            uploadedFile.$id;
        }
      }

      // Update the document in the database with the new data
      await databases.updateDocument(
        process.env.REACT_APP_DATABASE_ID,
        collectionId,
        item.$id,
        updateData
      );

      // Update local state to reflect changes
      if (type === "beat") {
        setBeats(
          beats.map((beat) =>
            beat.$id === item.$id ? { ...beat, ...updateData } : beat
          )
        );
      } else if (type === "tutorial") {
        setTutorials(
          tutorials.map((tutorial) =>
            tutorial.$id === item.$id
              ? { ...tutorial, ...updateData }
              : tutorial
          )
        );
      } else {
        setFashions(
          fashions.map((fashion) =>
            fashion.$id === item.$id ? { ...fashion, ...updateData } : fashion
          )
        );
      }

      setEditing(null);
      setNewImage(null);
    } catch (error) {
      console.error("Failed to update item", error);
    }
  };

  const createCoupon = async (category) => {
    if (!couponInputs[category].code || !couponInputs[category].discount) {
      return; // Remove alert
    }

    setCouponLoading(true); // Start loading animation

    const formattedCouponCode = couponInputs[category].code
      .trim()
      .toUpperCase();

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_COUPONS_ID,
        ID.unique(),
        {
          couponCode: formattedCouponCode,
          discountPercent: parseInt(couponInputs[category].discount, 10),
          category,
          validSellerMail: user.email,
        }
      );

      // Clear input fields after success
      setCouponInputs({
        ...couponInputs,
        [category]: { code: "", discount: "" },
      });

      fetchSellerCoupons(); // Refresh the list
    } catch (error) {
      console.error("Failed to create coupon", error);
    } finally {
      setCouponLoading(false); // Stop loading animation
    }
  };
  const deleteCoupon = async (couponId) => {
    if (!couponId) return;

    setDeletingCoupon(couponId); // Start loading

    try {
      await databases.deleteDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_COUPONS_ID,
        couponId
      );

      // Remove from local state
      setCoupons(coupons.filter((coupon) => coupon.$id !== couponId));
      setSelectedCoupons((prev) => {
        const updated = { ...prev };
        Object.keys(updated).forEach((key) => {
          if (updated[key]?.$id === couponId) {
            delete updated[key];
          }
        });
        return updated;
      });
    } catch (error) {
      console.error("Failed to delete coupon", error);
    } finally {
      setDeletingCoupon(null); // Stop loading
    }
  };

  const handleCouponSelect = (couponCode, category) => {
    const selectedCoupon = coupons.find(
      (coupon) => coupon.couponCode === couponCode
    );
    if (selectedCoupon) {
      setSelectedCoupons({ ...selectedCoupons, [category]: selectedCoupon });
    }
  };
  return (
    <div className="your-products-container">
      <div className="your-prod-title">
        <h2>Your Products</h2>{" "}
        <button onClick={handleSoldClick} className="sold-button">
          Sold Products
        </button>
      </div>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <BeatLoader color="#3498db" />
        </div>
      ) : (
        <>
          {/* Display Beats */}
          <div className="yourprod-header">
            <h3>Beats</h3>
            {/* Dropdown for Existing Coupons */}
            <select
              onChange={(e) => handleCouponSelect(e.target.value, "beat")}
            >
              <option value="">Select Coupon</option>
              {coupons
                .filter((coupon) => coupon.category === "beat")
                .map((coupon) => (
                  <option key={coupon.$id} value={coupon.couponCode}>
                    {coupon.couponCode} - {coupon.discountPercent}%
                  </option>
                ))}
            </select>

            {/* Delete Coupon Button */}
            {selectedCoupons["beat"] && (
              <button
                className="delete-button"
                onClick={() => deleteCoupon(selectedCoupons["beat"].$id)}
                disabled={deletingCoupon === selectedCoupons["beat"].$id}
              >
                {deletingCoupon === selectedCoupons["beat"].$id ? (
                  <ClipLoader size={12} />
                ) : (
                  <FaTrash />
                )}
              </button>
            )}

            {/* Input Field for New Coupon */}
            <input
              type="text"
              placeholder="New Coupon Code"
              value={couponInputs["beat"].code}
              onChange={(e) =>
                setCouponInputs({
                  ...couponInputs,
                  beat: { ...couponInputs["beat"], code: e.target.value },
                })
              }
            />
            <input
              type="number"
              placeholder="Discount %"
              value={couponInputs["beat"].discount}
              onChange={(e) =>
                setCouponInputs({
                  ...couponInputs,
                  beat: { ...couponInputs["beat"], discount: e.target.value },
                })
              }
            />

            <button
              onClick={() => createCoupon("beat")}
              disabled={couponLoading}
            >
              {couponLoading ? (
                <>
                  Creating.....
                  <ClipLoader size={12} />
                </>
              ) : (
                "Create Coupon"
              )}
            </button>
          </div>
          <div className="products-grid">
            {beats.map((beat) => (
              <div key={beat.$id} className="product-item">
                <div className="product-image-container">
                  <img
                    src={beat.imageUrl}
                    alt={beat.beatName}
                    className="product-image"
                  />
                  <button
                    className="yp-beat-play-button"
                    onClick={() => togglePlay(beat)}
                  >
                    {playingBeat &&
                    playingBeat.$id === beat.$id &&
                    !audio.paused ? (
                      <BsPauseFill />
                    ) : (
                      <BsPlayFill />
                    )}
                  </button>{" "}
                </div>
                {editing === beat.$id && editValues.type === "beat" ? (
                  <div>
                    <div className="tutorial-yp">
                      <p>Beat:</p>
                      <input
                        type="text"
                        name="name"
                        value={editValues.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="tutorial-yp">
                      <p>₹</p>
                      <input
                        type="number"
                        name="cost"
                        value={editValues.cost}
                        onChange={handleInputChange}
                      />{" "}
                    </div>
                    <div className="tutorial-yp">
                      <p>Tempo:</p>
                      <input
                        type="number"
                        name="tempo"
                        value={editValues.tempo || ""}
                        onChange={handleInputChange}
                        placeholder="Tempo (bpm)"
                      />
                    </div>
                    <div className="tutorial-yp">
                      <p>Albumart:</p>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        placeholder="Choose an image file"
                      />
                    </div>
                    <div className="tutorial-yp">
                      <p>Genres:</p>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={genreOptions}
                        onChange={handleGenreChange}
                        value={selectedGenres}
                        placeholder="Edit the Genres"
                        classNamePrefix="filter-dropdown"
                        className="filter-dropdown"
                        styles={styles}
                        // Adjusts the menu placement dynamically
                        // Use 'fixed' to help with viewport overflow issues
                        menuPortalTarget={document.body} // Render menu within the body DOM as a portal
                      />
                    </div>
                    <div className="tutorial-yp">
                      <p>Audio File:</p>
                      <input
                        type="file"
                        accept="audio/mpeg, audio/wav"
                        onChange={(e) =>
                          handleAudioUpload(e.target.files[0], beat)
                        }
                      />
                    </div>
                    <button
                      className="save-button"
                      onClick={() => handleSave(beat)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="yourprod-details">
                    <div> {beat.beatName} </div>
                    <div> ₹{beat.cost} </div>
                    <div>{beat.tempo} bpm</div>
                    <div>{beat.genre} </div>
                  </div>
                )}
                <div>
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(beat, "beat")}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteClick(beat.$id)}
                    disabled={deleting === beat.$id}
                  >
                    {deleting === beat.$id ? (
                      <ClipLoader size={12} />
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="yourprod-header">
            {/* Display Tutorials */}
            <h3>Tutorials</h3>
            {/* Dropdown for Existing Coupons */}
            <select
              onChange={(e) => handleCouponSelect(e.target.value, "tutorial")}
            >
              <option value="">Select Coupon</option>
              {coupons
                .filter((coupon) => coupon.category === "tutorial")
                .map((coupon) => (
                  <option key={coupon.$id} value={coupon.couponCode}>
                    {coupon.couponCode} - {coupon.discountPercent}%
                  </option>
                ))}
            </select>

            {/* Delete Button */}
            {selectedCoupons["tutorial"] && (
              <button
                className="delete-button"
                onClick={() => deleteCoupon(selectedCoupons["tutorial"].$id)}
                disabled={deletingCoupon === selectedCoupons["tutorial"].$id}
              >
                {deletingCoupon === selectedCoupons["tutorial"].$id ? (
                  <ClipLoader size={12} />
                ) : (
                  <FaTrash />
                )}
              </button>
            )}

            {/* Input Field for New Coupon */}
            <input
              type="text"
              placeholder="New Coupon Code"
              value={couponInputs["tutorial"].code}
              onChange={(e) =>
                setCouponInputs({
                  ...couponInputs,
                  tutorial: {
                    ...couponInputs["tutorial"],
                    code: e.target.value,
                  },
                })
              }
            />
            <input
              type="number"
              placeholder="Discount %"
              value={couponInputs["tutorial"].discount}
              onChange={(e) =>
                setCouponInputs({
                  ...couponInputs,
                  tutorial: {
                    ...couponInputs["tutorial"],
                    discount: e.target.value,
                  },
                })
              }
            />

            <button
              onClick={() => createCoupon("tutorial")}
              disabled={couponLoading}
            >
              {couponLoading ? (
                <>
                  Creating.....
                  <ClipLoader size={12} />
                </>
              ) : (
                "Create Coupon"
              )}
            </button>
          </div>
          <div className="products-grid">
            {tutorials.map((tutorial) => (
              <div key={tutorial.$id} className="product-item">
                <img
                  src={tutorial.thumbnailUrl}
                  alt={tutorial.tutorialTitle}
                  className="product-image"
                />
                {editing === tutorial.$id && editValues.type === "tutorial" ? (
                  <div>
                    <div className="tutorial-yp">
                      <p>Name:</p>
                      <input
                        type="text"
                        name="name"
                        value={editValues.name}
                        onChange={handleInputChange}
                      />{" "}
                    </div>
                    <div className="tutorial-yp">
                      <p>₹</p>
                      <input
                        type="number"
                        name="cost"
                        value={editValues.cost}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="tutorial-yp">
                      <p>Descptn:</p>
                      <input
                        type="text"
                        name="description"
                        value={editValues.description}
                        onChange={handleInputChange}
                        placeholder="Add a description"
                      />{" "}
                    </div>
                    <p>Thumbnail:</p>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <button
                      className="save-button"
                      onClick={() => handleSave(tutorial)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="yourprod-details">
                    <div>{tutorial.tutorialTitle}</div>
                    <div>₹{tutorial.cost}</div>
                  </div>
                )}
                <div>
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(tutorial, "tutorial")}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteClick(tutorial.$id)}
                    disabled={deleting === tutorial.$id}
                  >
                    {deleting === tutorial.$id ? (
                      <ClipLoader size={12} />
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="yourprod-header">
            {/* Display Fashion Items */}
            <h3>Fashion Items</h3>
            {/* Dropdown for Existing Coupons */}
            <select
              onChange={(e) => handleCouponSelect(e.target.value, "fashion")}
            >
              <option value="">Select Coupon</option>
              {coupons
                .filter((coupon) => coupon.category === "fashion")
                .map((coupon) => (
                  <option key={coupon.$id} value={coupon.couponCode}>
                    {coupon.couponCode} - {coupon.discountPercent}%
                  </option>
                ))}
            </select>

            {/* Delete Button */}
            {selectedCoupons["fashion"] && (
              <button
                className="delete-button"
                onClick={() => deleteCoupon(selectedCoupons["fashion"].$id)}
                disabled={deletingCoupon === selectedCoupons["fashion"].$id}
              >
                {deletingCoupon === selectedCoupons["fashion"].$id ? (
                  <ClipLoader size={12} />
                ) : (
                  <FaTrash />
                )}
              </button>
            )}

            {/* Input Field for New Coupon */}
            <input
              type="text"
              placeholder="New Coupon Code"
              value={couponInputs["fashion"].code}
              onChange={(e) =>
                setCouponInputs({
                  ...couponInputs,
                  fashion: { ...couponInputs["fashion"], code: e.target.value },
                })
              }
            />
            <input
              type="number"
              placeholder="Discount %"
              value={couponInputs["fashion"].discount}
              onChange={(e) =>
                setCouponInputs({
                  ...couponInputs,
                  fashion: {
                    ...couponInputs["fashion"],
                    discount: e.target.value,
                  },
                })
              }
            />

            <button
              onClick={() => createCoupon("fashion")}
              disabled={couponLoading}
            >
              {couponLoading ? (
                <>
                  Creating.....
                  <ClipLoader size={12} />
                </>
              ) : (
                "Create Coupon"
              )}
            </button>
          </div>
          <div className="products-grid">
            {fashions.map((fashion) => (
              <div key={fashion.$id} className="product-item">
                <img
                  src={fashion.imageUrlView}
                  alt={fashion.itemName}
                  className="product-image"
                />
                {editing === fashion.$id && editValues.type === "fashion" ? (
                  <div>
                    <div className="tutorial-yp">
                      <p>Name:</p>
                      <input
                        type="text"
                        name="name"
                        value={editValues.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="tutorial-yp">
                      <p>₹ </p>
                      <input
                        type="number"
                        name="cost"
                        value={editValues.cost}
                        onChange={handleInputChange}
                      />{" "}
                    </div>
                    <div className="tutorial-yp">
                      <p>Image:</p>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div>
                    <button
                      className="save-button"
                      onClick={() => handleSave(fashion)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="yourprod-details">
                    <div>{fashion.itemName}</div>
                    <div>₹{fashion.cost}</div>
                  </div>
                )}
                <div>
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(fashion, "fashion")}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() =>
                      handleDeleteFashionClick(
                        process.env.REACT_APP_COLLECTION_FASHION_ID,
                        fashion.$id,
                        [fashion.fashionUrl],
                        process.env.REACT_APP_BUCKET_FASHION_ID
                      )
                    }
                    disabled={deleting === fashion.$id}
                  >
                    {deleting === fashion.$id ? (
                      <ClipLoader size={12} />
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}{" "}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={confirmDeletion}
      />
    </div>
  );
};

export default YourProducts;
