import React, { useState, useEffect } from "react";
import { useUser } from "../UserContext";
import { useNavigate } from "react-router-dom"; // Import navigation hook
import client from "../appwriteConfig";
import { ID, Databases, Query } from "appwrite";

const databases = new Databases(client);

const Room = () => {
  const { user } = useUser();
  const [challengeCode, setChallengeCode] = useState("");
  const [room, setRoom] = useState(null);
  const navigate = useNavigate(); // Use navigate hook

  const databaseId = process.env.REACT_APP_DATABASE_ID;
  const roomCollectionId = process.env.REACT_APP_COLLECTION_ROOMS;

  // Handle room creation
  const handleCreateRoom = async () => {
    const generatedCode = Math.random().toString(36).substring(2, 8); // Generate challenge code
    setChallengeCode(generatedCode);
    try {
      const result = await databases.createDocument(
        databaseId,
        roomCollectionId,
        generatedCode,
        {
          challengeCode: generatedCode,
          creatorId: user.email,
          pairedUserId: null, // Initially null, waiting for second user
        }
      );
      setRoom(result); // Set room for creator

      // Subscribe to room updates (to know when second user joins)
      const unsubscribe = client.subscribe(
        `databases.${databaseId}.collections.${roomCollectionId}.documents.${result.$id}`,
        (response) => {
          if (
            response.events.includes(
              "databases.*.collections.*.documents.*.update"
            )
          ) {
            const updatedRoom = response.payload;
            if (updatedRoom.pairedUserId) {
              // When the second user joins (pairedUserId gets filled), navigate to PrivateRoom
              navigate("/PrivateRoom", {
                state: { roomId: updatedRoom.challengeCode },
              });
            }
          }
        }
      );

      return () => {
        unsubscribe(); // Unsubscribe when component unmounts
      };
    } catch (error) {
      console.error("Error creating room:", error);
    }
  };

  // Handle room joining with challenge code for second user
  const handleJoinRoom = async () => {
    try {
      const result = await databases.getDocument(
        databaseId,
        roomCollectionId,
        challengeCode
      );
      console.log(result);
      if (result) {
        const roomData = result;
        await databases.updateDocument(
          databaseId,
          roomCollectionId,
          roomData.$id,
          {
            pairedUserId: user.email,
          }
        );
        setRoom(roomData); // Join the room
        // Navigate to /PrivateRoom after successful join
        navigate("/PrivateRoom", { state: { roomId: roomData.challengeCode } });
      } else {
        console.log("Invalid challenge code");
      }
    } catch (error) {
      console.error("Error joining room:", error);
    }
  };

  return (
    <div>
      <h2>Room Setup</h2>
      {user ? (
        <div className="user-info">
          <p>
            <strong>Name:</strong> {user.name || "Anonymous"}
          </p>
          <p>
            <strong>Email:</strong> {user.email || "No email provided"}
          </p>
        </div>
      ) : (
        <p>No user information available</p>
      )}

      {!room ? (
        <div>
          {/* Room creation or joining */}
          <button onClick={handleCreateRoom}>Create Room</button>
          <div>
            <input
              type="text"
              placeholder="Enter challenge code"
              value={challengeCode}
              onChange={(e) => setChallengeCode(e.target.value)}
            />
            <button onClick={handleJoinRoom}>Join Room</button>
          </div>
        </div>
      ) : (
        <p>Challenge Code: {challengeCode}</p>
      )}
    </div>
  );
};

export default Room;
