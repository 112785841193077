import React from "react";
import { FiShare2 } from "react-icons/fi"; // Using FiShare2 icon from Feather icons in React Icons

const ShareButton = () => {
  const handleShare = () => {
    const url = window.location.href; // Get the current URL
    navigator.clipboard.writeText(url).then(
      () => {
        alert("URL copied to clipboard!"); // Notify the user (optional)
      },
      (err) => {
        console.error("Failed to copy: ", err); // Handle potential errors
      }
    );
  };

  return (
    <button
      onClick={handleShare}
      style={{
        border: "none",
        background: "none",
        cursor: "pointer",
        color: "#0ff",
        textShadow:
          "0 0 5px rgba(0, 255, 255, 0.5), 0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 1), 0 0 30px rgba(0, 255, 255, 1), 0 0 40px rgba(0, 255, 255, 1), 0 0 55px rgba(0, 255, 255, 1), 0 0 75px rgba(0, 255, 255, 1)",
        outline: "none",
      }}
    >
      <FiShare2 size={24} />
      {/* Icon size can be adjusted */}
    </button>
  );
};

export default ShareButton;
