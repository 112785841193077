import React, { useState, useEffect, useRef } from "react";
import { Databases } from "appwrite";
import client from "./appwriteConfig";
import "./css/BeatMaker.css";
import InstrumentAudioContext from "./InstrumentAudioContext";
import KitModal from "./KitModal";
import { FaPlay, FaPause, FaCircle, FaClock } from "react-icons/fa";
import Sequencer from "./Sequencer";
import { BeatLoader, ClipLoader } from "react-spinners";
import InstrumentSeq from "./InstrumentSeq";
import { PatternInsSeqProvider } from "./PatternInsSeqContext.js";
import SongPlayer1 from "./SongPlayer1";
import SongPlayer from "./SongPlayer";

function BeatMaker() {
  const [instrumentData, setInstrumentData] = useState([]);
  const [pads, setPads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [sequence, setSequence] = useState(
    Array(pads.length).fill(Array(8).fill(false))
  );
  const [transformedData, setTransformedData] = useState(null);
  const [selectedKit, setSelectedKit] = useState(null);
  const [bpm, setBpm] = useState(InstrumentAudioContext.getBpm());
  const [isLedPlaying, setIsLedPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [recordedSteps, setRecordedSteps] = useState([]);
  const activeTouchesRef = useRef(new Set());

  useEffect(() => {
    fetchPads();
  }, []);

  useEffect(() => {
    pads.forEach((pad) => {
      InstrumentAudioContext.preloadAudio(pad.kitUrl);
    });
  }, [pads]);

  useEffect(() => {
    if (!isRecording && recordedSteps.length > 0) {
      const transformedData = transformRecordedSteps(recordedSteps);
      console.log("Transformed Recorded Notes:", transformedData);
    }
  }, [isRecording, recordedSteps]);

  const fetchPads = async () => {
    setLoading(true);
    try {
      const databases = new Databases(client);
      const { documents } = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_KIT_ID
      );
      const typedPads = documents.map((pad) => ({
        ...pad,
        type: pad.kitId,
      }));
      setPads(typedPads);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch pads:", error);
      setError("Failed to load pads.");
      setLoading(false);
    }
  };

  const handlePadClick = (kitUrl, kitName) => {
    InstrumentAudioContext.playSound(kitUrl);

    if (!isLedPlaying && isRecording) {
      startLedSequence();
    }

    if (isRecording) {
      const stepData = { kitName, step: currentStep };
      setRecordedSteps((prevSteps) => [...prevSteps, stepData]);
    }
  };

  const handleMouseDown = (kitUrl, kitName) => {
    handlePadClick(kitUrl, kitName);
  };

  const handleTouchStart = (e, kitUrl, kitName) => {
    e.preventDefault();
    Array.from(e.changedTouches).forEach((touch) => {
      const touchId = touch.identifier;
      if (!activeTouchesRef.current.has(touchId)) {
        activeTouchesRef.current.add(touchId);
        handlePadClick(kitUrl, kitName);
      }
    });
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    Array.from(e.changedTouches).forEach((touch) => {
      const touchId = touch.identifier;
      activeTouchesRef.current.delete(touchId);
    });
  };

  const transformRecordedSteps = (recordedSteps) => {
    const result = {};

    recordedSteps.forEach(({ kitName, step }) => {
      const lowerCaseKitName = kitName.toLowerCase();
      if (!result[lowerCaseKitName]) {
        result[lowerCaseKitName] = {
          sequence: Array(16).fill(0),
          kitVolume: 0.5,
        };
      }
      result[lowerCaseKitName].sequence[step] = 1;
    });

    return result;
  };

  const startLedSequence = () => {
    const interval = (60 / bpm) * 250;
    setRecordedSteps([]); // Clear recorded steps at the start of each recording session
    setCurrentStep(0);

    const intervalId = setInterval(() => {
      setCurrentStep((prevStep) => {
        const nextStep = (prevStep + 1) % 16;
        if (nextStep === 0 && isRecording) {
          setIsRecording(false);
        }
        if (nextStep === 0) {
          clearInterval(intervalId);
          setIsLedPlaying(false);
        }
        return nextStep;
      });
    }, interval);

    setIsLedPlaying(true);
  };

  const handleMapButtonClick = () => {
    const data = transformRecordedSteps(recordedSteps);
    setTransformedData(data);
    console.log("Transformed Data:", data);
  };

  const handleClockButtonClick = () => {
    InstrumentAudioContext.toggleMetronome();
  };

  return (
    <div className="beat-maker">
      <h2>Beat Maker</h2>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <BeatLoader color="#3498db" />
        </div>
      ) : (
        <>
          {" "}
          <div className="bmaker-header">
            <button className="add-sound" onClick={() => setShowModal(true)}>
              Add kit
            </button>
            <button
              className="record-button4"
              onClick={() => setIsRecording((prev) => !prev)}
            >
              <FaCircle color={isRecording ? "green" : "black"} /> <p>REC</p>
            </button>{" "}
            <button onClick={handleMapButtonClick} className="map-button">
              Map
            </button>{" "}
            <button
              onClick={handleClockButtonClick}
              className="save-pattern-button"
            >
              <FaClock />
            </button>
          </div>{" "}
          <div className="led-indicator">
            {Array(16)
              .fill()
              .map((_, index) => (
                <div
                  key={index}
                  className={`led-step ${
                    currentStep === index ? "active" : ""
                  } ${index % 4 === 0 ? "special-step" : ""}`}
                />
              ))}
          </div>
          <div className="pads">
            {pads.map((pad) => (
              <button
                key={pad.$id}
                className="pad"
                data-kitid={pad.kitUrl}
                onMouseDown={() => handleMouseDown(pad.kitUrl, pad.kitName)}
                onTouchStart={(e) =>
                  handleTouchStart(e, pad.kitUrl, pad.kitName)
                }
                onTouchEnd={handleTouchEnd}
              >
                {pad.kitName || "Unnamed Pad"}
              </button>
            ))}
          </div>
          {showModal && (
            <KitModal
              onClose={() => setShowModal(false)}
              refreshPads={fetchPads}
            />
          )}
          <PatternInsSeqProvider>
            <Sequencer
              pads={pads}
              sequence={sequence}
              setSequence={setSequence}
              isPlaying={isPlaying}
              bpm={bpm}
              setBpm={setBpm}
              transformedData={transformedData}
              updateInstruments={(instruments) =>
                setInstrumentData(instruments)
              }
            />
            {/* <SongPlayer bpm={bpm} setBpm={setBpm} pads={pads} /> */}
            <SongPlayer bpm={bpm} setBpm={setBpm} pads={pads} />
          </PatternInsSeqProvider>
        </>
      )}
    </div>
  );
}

export default BeatMaker;
